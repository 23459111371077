import React, { cloneElement, useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute, useModal } from "../components";
import { connect } from "react-redux";
import { RootState } from "../state";
import { TabProps, tabExist, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";

import BuyerList from "../views/buyer/buyerDatatables";
import BuyerPage from "../views/buyer/buyerPages";
import { useLazyQuery } from "@apollo/react-hooks";
import { GetBuyers } from "../common/models/types/getBuyers";
import { GET_BUYERS } from "../common/models/buyers";
import { closedForm, FormDataProps, triggeredForm } from "../state/formReducer";
import { updateBuyerMainForms } from "../state/buyerSectionReducer";
import NoteAddIcon from '@material-ui/icons/NoteAdd';



interface Props {
  dispatch: Function;
  tabsSection: RootState['tabsSection'];
  buyerId?: string;
  close?: string;
  formSection: RootState['formsSection'];
  buyerMain: any;
}

const TAB_CONTAINER = "buyer-list-tabs";

const Buyers = ({ tabsSection, dispatch, buyerId, navigate, close: closeTab, formSection, buyerMain }: Props & RouteComponentProps) => {
  
  const [getAllBuyers, { data, error, loading, called, refetch }] = useLazyQuery<GetBuyers>(GET_BUYERS, { fetchPolicy: 'network-only' });
  
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabBuyers, setTabBuyers] = useState<TabProps[]>([]);
  const [formIds, setFormIds] = useState<string[]>();
  const [tabsInitialized, setTabsInitialized] = useState(false);
  const [formsInitialized, setFormsInitialized] = useState(false);
  const { Modal, closeModal, openModal } = useModal();

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink && navigate) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  const handleCloseBuyerTab = (tabs: TabProps[]) => {
    // navigate && navigate(`${tabs[tabFocus >= tabs.length ? 0 : tabs.length - 1].permalink}`);

    /* dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: tabFocus >= tabs.length ? 0 : tabs.length - 1,
          tabs: tabs,
        }
      })
    ); */

  };

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: (tabs.length - 1),
          tabs: tabs,
        }
      })
    );
  };

  const handleDocking = (formData: FormDataProps) => {
    if (formData) {
      const _tabExist = tabExist(tabBuyers, formData.formId);
      let _tabs: TabProps[] = (_tabExist > 0) ? tabBuyers?.filter((tab, index) => (index !== _tabExist)) : tabBuyers;

      if (formData.isDocked) {

        closeModal();
        // dispatch(updateTabContainers(
        //   {
        //     [TAB_CONTAINER]: {
        //       tabFocus: _tabs?.length,
        //       tabs: [
        //         ..._tabs,
        //         {
        //           title: formData.formTitle,
        //           tabId: formData.formId,
        //           content: (typeof formData.formComponent !== 'undefined' &&
        //             cloneElement(
        //               formData.formComponent,
        //               {
        //                 ...formData,
        //                 data: formData.formData,
        //                 close: formData.formProps.closeTab,
        //                 isDocked: formData.isDocked,
        //               }
        //             )
        //           ),
        //           closeTab: (tabs: TabProps[]) => {
        //             handleCloseTab(tabs);
        //           },
        //         },
        //       ],
        //     }
        //   }
        // ));
      } else if (formData.isModal) {
        dispatch(updateTabContainers(
          {
            [TAB_CONTAINER]: {
              //tabFocus: (_tabs.length - 1),
              tabFocus: formData?.formProps?.tabFocus ? formData?.formProps?.tabFocus : (tabFocus < _tabs.length) ? tabFocus : (_tabs.length - 1),
              tabs: _tabs,
            }
          }
        ));
        openModal({
          title: formData.formTitle,
          icon: <NoteAddIcon />,
          iconColor: "orange",
          content: (
            typeof formData.formComponent !== 'undefined' &&
            cloneElement(
              formData.formComponent,
              {
                ...formData,
                data: formData.formData,
                close: closeModal,
                isDocked: formData.isDocked,
              }
            )
          ),
          customHeaderButtons: <div />,
        });
      }
    }

    //defrag the form trigger
    dispatch(triggeredForm({ formId: formData.formId }));
  };

  useEffect(() => {
    if (tabsInitialized && formsInitialized && formIds && formSection) {
      formIds.forEach(formId => {
        if (formSection.formContainers[formId] && formSection.formContainers[formId].isTriggered) {
          handleDocking(formSection.formContainers[formId]);
        } else if (formSection.formContainers[formId] && formSection.formContainers[formId].isClosed) {
          const _tabExist = tabExist(tabBuyers, formId);
          let _tabs: TabProps[] = (_tabExist > 0) ? tabBuyers?.filter((tab, index) => (index !== _tabExist)) : tabBuyers;

          handleCloseTab(_tabs);
          dispatch(closedForm({ formId: formId }));
        }
      });
    }
  }, [tabsInitialized, formsInitialized, formIds, formSection]);

  useEffect(() => {
    getAllBuyers();
  }, []);

  useEffect(() => {
    if (Object.keys(buyerMain).length === 0) {
      dispatch(
        updateBuyerMainForms({
          buyerId: `buyers`,
          formIds: [
            `buyer-edit`,
            `buyer-add`,
          ]
        })
      );
    } else {
      setFormIds(buyerMain.formIds);
      setFormsInitialized(true);
    }
  }, [buyerMain]);

  useEffect(() => {
    if(!data || !tabsSection) return;

    if (!tabsSection.tabContainers[TAB_CONTAINER]) {
      console.log("TAB",data);
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Buyer",
          tabId: "buyer-list",
          content: <BuyerList />,
          permalink: `/buyers`,
        },
      ];

      if (buyerId && !closeTab) {
        const buyer = data.LDPConfigQueryGroup?.Buyer?.find((b) => String(b?.BuyerId) === buyerId);

        if(buyer){
          initialTabContainerFocus = 1;
          initialTabs = [
            ...initialTabs,
            {
              title: buyer.BuyerName,
              tabId: `buyer-details-${buyerId}`,
              content: <BuyerPage buyerId={buyerId} />,
              closeTab: (tabs: TabProps[]) => {
                navigate && navigate(`/buyers/${buyerId}/close`);
              },
              permalink: `/buyers/${buyerId}`,
            },
          ]
        }
      }

      const initialLeadsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialLeadsTabContainer));

    } else {
      if (tabsSection.tabContainers[TAB_CONTAINER]) {
        setTabBuyers(tabsSection.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(tabsSection.tabContainers[TAB_CONTAINER].tabFocus);
        setTabsInitialized(true);

        if(buyerId && !closeTab){
          const tabMatchIndex = tabsSection.tabContainers[TAB_CONTAINER].tabs.findIndex((t) => t.tabId === `buyer-details-${buyerId}`);

          if(tabMatchIndex > -1 && tabMatchIndex != tabsSection.tabContainers[TAB_CONTAINER].tabFocus){
            dispatch(updateTabContainers({
              [TAB_CONTAINER]: {
                tabFocus: tabMatchIndex,
                tabs: [ ...tabsSection.tabContainers[TAB_CONTAINER].tabs ],
              }
            }));

          } else if(tabMatchIndex < 0) {
            const buyer = data.LDPConfigQueryGroup?.Buyer?.find((b) => String(b?.BuyerId) === buyerId);

            buyer && dispatch(updateTabContainers({
              [TAB_CONTAINER]: {
                tabFocus: tabsSection.tabContainers[TAB_CONTAINER].tabs.length,
                tabs: [ 
                  ...tabsSection.tabContainers[TAB_CONTAINER].tabs,  
                  {
                    title: buyer.BuyerName,
                    tabId: `buyer-details-${buyerId}`,
                    content: <BuyerPage buyerId={buyerId} />,
                    closeTab: (tabs: TabProps[]) => {
                      navigate && navigate(`/buyers/${buyerId}/close`);
                    },
                    permalink: `/buyers/${buyerId}`,
                  }
                ],
              }
            }));
          }
        } else if(buyerId && !!closeTab) {
          console.log('Closing tab for Buyer ', buyerId);

          const targetTabId = `buyer-details-${buyerId}`;
          const tabMatch = tabsSection.tabContainers[TAB_CONTAINER].tabs.find((t) => t.tabId === targetTabId);

          if(tabMatch){
            dispatch(updateTabContainers({
              [TAB_CONTAINER]: {
                tabFocus: 0,
                tabs: tabsSection.tabContainers[TAB_CONTAINER].tabs.filter((t) => t.tabId !== targetTabId),
              }
            }));
          } else {
            navigate && navigate(`/buyers`);
          }
          

        } else if(!buyerId && tabsSection.tabContainers[TAB_CONTAINER].tabFocus !== 0) {
          dispatch(updateTabContainers({
            [TAB_CONTAINER]: {
              tabFocus: 0,
              tabs: [ ...tabsSection.tabContainers[TAB_CONTAINER].tabs ],
            }
          }));

        }
      }
    }
  }, [tabsSection, data]);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={tabBuyers}
        onCloseTab={setTabBuyers}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
       <Modal />
    </ContentContainer>
  );
};

const BuyerWrapper = connect((state: RootState) => ({
  tabsSection: state.tabsSection,
  formSection: state.formsSection,
  buyerMain: state.buyerSection.buyerMain,
}), null)(Buyers);

export default ({ location }: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute component={BuyerWrapper} path="buyers/" pagetitle="LDP Buyers"/>
        <PrivateRoute component={BuyerWrapper} path="buyers/:buyerId" pagetitle="LDP Buyers"/>
        <PrivateRoute component={BuyerWrapper} path="buyers/:buyerId/:close" pagetitle="LDP Buyers"/>
      </Router>
    </Layout>
  );
};