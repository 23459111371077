import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { useModal, ContentLoading } from "../../components";
import { toast } from "react-toastify";
import { GET_BUYERS } from "../../common/models/buyers";
import { GetBuyers } from "../../common/models/types/GetBuyers";
import ViewBuyer from "./buyerView";
import { BuyerFormModal } from "./buyerModal";
import { AddCircle } from "@material-ui/icons";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ContactsIcon from '@material-ui/icons/Contacts';
import LaunchIcon from "@material-ui/icons/Launch";
import { IconButton, Button, Grid, Hidden, Box, Checkbox, FormControlLabel, FormGroup, FormLabel, TextField, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import { BuyerContactFormModal } from "./buyerContactFormModal";

import { signalBuyerRefreshCarriedOut } from "../../state/buyerSectionReducer";
import { updateTabContainers } from "../../state/tabsReducer";
import { TabProps } from "../../components/tabs";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { updateBuyers } from "../../state/dataCacheReducer";
import { createDateFilter } from "../../components/customMuiDatatableFilter";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { navigate } from "gatsby";


interface BuyerListProps {
  buyerTabs: any;
  buyerRecordSaved: boolean;
  dispatch: Function;
  buyers: GetBuyers;
  refetch: Function;
}

const TAB_CONTAINER = "buyer-list-tabs";

const BuyerList = ({ buyerTabs, buyerRecordSaved, dispatch }: BuyerListProps) => {
  const [getAllBuyers, { data, refetch }] = useLazyQuery<GetBuyers>(GET_BUYERS, { fetchPolicy: 'network-only' });

  const googleUserMeta = GoogleUserMeta();

  const [buyersData, setBuyersData] = useState<GetBuyers>();

  

  /* const addTab = (title: string, content: JSX.Element) => {
    var currentTabs = buyerTabs.tabContainers[TAB_CONTAINER].tabs;
    dispatch(updateTabContainers({
      [TAB_CONTAINER]: {
        tabFocus: currentTabs.length,
        tabs: [
          ...currentTabs,
          {
            tabId: `buyer-${currentTabs.length}`,
            title,
            content,
            closeTab: handleCloseTab
          },
        ],
      }
    }));
  } */
  useEffect(() => {
    getAllBuyers();
  }, []);

  const columnsMUI = [
    {
      name: "BuyerId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <IconButton
              onClick={() => navigate("/buyers/" + value)}
              /* onClick={() =>
                addTab(`${tableMeta.tableData[tableMeta.rowIndex][2]}`, <ViewBuyer buyerId={value} />)
              } */
            >
              <LaunchIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "BuyerId",
      label: "Buyer ID",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "BuyerName",
      label: "Buyer Name",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "AccountManagerName",
      label: "Account Manager Name",
      options: {
        viewColumns: false,
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        //     viewColumns: false,        
        filter: true,        
        customBodyRender: (value: any) => {
          /* return value
            ? mument(value)
              .local()
              .format("YYYY-MM-DD")
            : ""; */
            return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        ...createDateFilter()
      }
    },
  ];

  const { Modal, closeModal, openModal, setContent } = useModal();

  useEffect(() => {
    if(data){
      // update buyer list cache
      dispatch && dispatch(
        updateBuyers(data?.LDPConfigQueryGroup?.Buyer || [])
      );
      toast.dismiss();
      dispatch(signalBuyerRefreshCarriedOut());
      setBuyersData(data);
    }

    if (data && buyerRecordSaved) {
      toast.info("Refreshing List");
      getAllBuyers();
    }
  }, [data]);

  const options: any = {
    searchOpen: true,
    filterType: "dropdown",
    sortFilterList: false,
    selectableRows: "none",
    responsive: "vertical",        
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "Add New Buyer",
                    icon: <PersonAddIcon />,
                    iconColor: "green",
                    content: (
                      <BuyerFormModal
                        action={"create"}
                        data={null}
                        close={closeModal}
                        refetch={refetch}
                        googleUserMeta={googleUserMeta} 
                      />
                    ),
                  });
                }}
              >
                Create New Buyer
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "Add New Buyer Contact",
                    icon: <ContactsIcon />,
                    iconColor: "green",
                    content: (
                      <BuyerContactFormModal
                        action={"create"}
                        data={null}
                        close={closeModal}
                        refetch={refetch}
                        googleUserMeta={googleUserMeta} 
                      />
                    ),
                  });
                }}
              >
                Create New Contact Info
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!buyersData && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId="buyer-list"
          restoreFilters={true}
          title={"Buyers"}
          data={buyersData?.LDPConfigQueryGroup?.Buyer}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: any) => ({
  buyerRecordSaved: state.buyerSection.buyerRecordSaved,
  buyerTabs: state.tabsSection,
}), null)(BuyerList);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
