import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  GET_BUYER_SUBVERTICAL_MONETARY_CAPACITY,
  MERGE_BUYER_SUBVERTICAL_MONETARY_CAPACITY_CONFIG,
} from "../../common/models/buyerCapacity";
import { GET_IS_AFFILIATE_ID_ACTIVE } from "../../common/models/contractMonetaryCapacity";
import {
  BuyerSubVerticalMonetaryCapacityConfig,
  BuyerSubVerticalMonetaryCapacityConfigVariables,
  BuyerSubVerticalMonetaryCapacityConfig_LDPConfigQueryGroup_BuyerSubVerticalMonetaryCapacityConfig,
} from "../../common/models/types/BuyerSubVerticalMonetaryCapacityConfig";
import {
  GetSubVertical,
  GetSubVertical_LDPConfigQueryGroup_SubVertical,
} from "../../common/models/types/GetSubVertical";
import {
  GetVertical,
  GetVertical_LDPConfigQueryGroup_Vertical,
} from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { eventTracker as tracker } from "../../components/tracker";
import { MergeBuyerSubVerticalMonetaryCapacityConfigInputType } from "../../types/graphql-global-types";

interface SubVerticalOption {
  vertical: GetVertical_LDPConfigQueryGroup_Vertical;
  subvertical: GetSubVertical_LDPConfigQueryGroup_SubVertical;
  exists: boolean;
}

interface BuyerSubVerticalMonetaryCapacityFormModalProps {
  buyerId: any;
  data: BuyerSubVerticalMonetaryCapacityConfig_LDPConfigQueryGroup_BuyerSubVerticalMonetaryCapacityConfig | null;
  action: string;
  close: Function;
  refetch?: Function;
  inUsedList?: BuyerSubVerticalMonetaryCapacityConfig_LDPConfigQueryGroup_BuyerSubVerticalMonetaryCapacityConfig[];
  existingAffiliateIds?: {
    AffiliateId: number | null;
    SubVerticalName: string | null;
  }[];
}

const formError = {
  SubVerticalName: {
    required: {
      value: true,
      message: "SubVertical Name is required.",
    },
  },
};

export const BuyerSubVerticalMonetaryCapacityFormModal = ({
  action,
  buyerId,
  // inUsedList,
  data,
  close,
  refetch,
  existingAffiliateIds,
}: BuyerSubVerticalMonetaryCapacityFormModalProps) => {
  const googleUserMeta = GoogleUserMeta();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    errors,
    watch,
    getValues,
  } = useForm<MergeBuyerSubVerticalMonetaryCapacityConfigInputType>();

  const watchAffiliateId = watch("AffiliateId");
  const classes = useStyles();

  const eventTracker = ({ data }: any) => {
    const blockers: any = { "@": "[at]", ".": "[dot]" };
    tracker({
      name: "CreateNewBuyerSubVerticalMonetaryCapacity",
      caption: "Track Create New Buyer SubVertical Monetary Capacity",
      values: {
        ...data,
        email:
          googleUserMeta?.email?.replace(/\@|\./g, (it) => blockers[it]) ??
          null,
      },
    });
  };

  const [
    getBuyerSubVertMonetaryCapacities,
    { data: inUsedList, error, loading, called },
  ] = useLazyQuery<
    BuyerSubVerticalMonetaryCapacityConfig,
    BuyerSubVerticalMonetaryCapacityConfigVariables
  >(GET_BUYER_SUBVERTICAL_MONETARY_CAPACITY, { fetchPolicy: "network-only" });

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const [
    subVerticalInput,
    setSubVerticalInput,
  ] = useState<SubVerticalOption | null>(null);
  const [subVerticalOptions, setSubVerticalOptions] = useState<
    SubVerticalOption[]
  >([]);
  const [saveBuyerSubVerticalMonetaryCapacity] = useMutation(
    MERGE_BUYER_SUBVERTICAL_MONETARY_CAPACITY_CONFIG
  );

  const [
    isAffiliateIdActive,
    { data: isAffiliateIdActiveData, loading: isAffiliateIdActiveLoading },
  ] = useLazyQuery(GET_IS_AFFILIATE_ID_ACTIVE);

  useEffect(() => {
    getAllVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
    getBuyerSubVertMonetaryCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`,
      },
    });
  }, []);

  useEffect(() => {
    getAllSubVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [verticalData, verticalError]);

  const processInput = (
    input: string | number | null | undefined
  ): number | null => {
    // Check if the input is null, undefined, or an empty string
    if (input === null || input === undefined || input === "") {
      return null;
    }

    // Convert the input to a number and return
    return Number(input);
  };

  useEffect(() => {
    if (data && verticalData && subVerticalData && inUsedList) {
      const options: SubVerticalOption[] = [];
      for (const sv of subVerticalData.LDPConfigQueryGroup?.SubVertical || []) {
        const v = (verticalData?.LDPConfigQueryGroup?.Vertical || []).find(
          (vert) => vert?.VerticalId === sv?.VerticalId
        );
        if (v && sv) {
          options.push({
            vertical: v,
            subvertical: sv,
            exists: !!inUsedList.LDPConfigQueryGroup?.BuyerSubVerticalMonetaryCapacityConfig?.find(
              (cap) =>
                cap?.SubVerticalName === sv.SubVerticalName &&
                cap?.AffiliateId === processInput(watchAffiliateId)
            ),
          });
        }
      }

      options.sort((sv1, sv2) =>
        `${sv1.vertical.VerticalName} - ${sv1.subvertical.SubVerticalName}` >
        `${sv2.vertical.VerticalName} - ${sv2.subvertical.SubVerticalName}`
          ? 1
          : -1
      );
      // console.log(options);
      setSubVerticalOptions(options);

      // setBuyerCapLoading(false);
    }
  }, [data, verticalData, subVerticalData, watchAffiliateId, inUsedList]);

  useEffect(() => {
    // if (watchAffiliateId) {
    // console.log("watchAffiliateId", watchAffiliateId);
    setSubVerticalInput(null);
    // }
  }, [watchAffiliateId]);

  useEffect(() => {
    if (data && subVerticalOptions) {
      setSubVerticalInput(
        subVerticalOptions?.find(
          (it) => it.subvertical?.SubVerticalName === data.SubVerticalName
        ) || null
      );
    }
  }, [subVerticalOptions]);

  const [saveState, setSaveState] = useState<
    "preparing" | "validating" | "validated" | "ready"
  >("preparing");

  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  useEffect(() => {
    if (
      saveState === "validating" &&
      !isAffiliateIdActiveLoading &&
      isAffiliateIdActiveData
    ) {
      //this step ensures that API AffiliateId validation is complete before setting field errors
      setSaveState("validated");
    } else if (
      saveState === "validated" &&
      watchAffiliateId &&
      isAffiliateIdActiveData
    ) {
      if (isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive) {
        setSaveState("ready");
      } else {
        setSaveState("preparing");
        setError("AffiliateId", {
          type: "error",
          message: "AffiliateId is inactive, please set a different value",
        });
        setDisableButtons(false);
      }
    } else if (saveState === "ready" && watchAffiliateId) {
      handleSaveBuyerSubVerticalMonetaryCapacity();
    }
  }, [
    isAffiliateIdActiveLoading,
    saveState,
    isAffiliateIdActiveData,
    watchAffiliateId,
  ]);

  const handleSaveBuyerSubVerticalMonetaryCapacity = () => {
    let mergeBuyerSubVerticalMonetaryCapacityConfigParams = getValues();
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.UserId =
      googleUserMeta?.email || '';
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.BuyerSubVerticalMonetaryCapacityConfigId =
      mergeBuyerSubVerticalMonetaryCapacityConfigParams?.BuyerSubVerticalMonetaryCapacityConfigId;
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.BuyerId =
      mergeBuyerSubVerticalMonetaryCapacityConfigParams?.BuyerId;
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.MaxMonthlySubVerticalBudget = mergeBuyerSubVerticalMonetaryCapacityConfigParams?.MaxMonthlySubVerticalBudget
      ? parseInt(
          mergeBuyerSubVerticalMonetaryCapacityConfigParams?.MaxMonthlySubVerticalBudget
        )
      : null;
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.MaxWeeklySubVerticalBudget = mergeBuyerSubVerticalMonetaryCapacityConfigParams?.MaxWeeklySubVerticalBudget
      ? parseInt(
          mergeBuyerSubVerticalMonetaryCapacityConfigParams?.MaxWeeklySubVerticalBudget
        )
      : null;
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.MaxDailySubVerticalBudget = mergeBuyerSubVerticalMonetaryCapacityConfigParams?.MaxDailySubVerticalBudget
      ? parseInt(
          mergeBuyerSubVerticalMonetaryCapacityConfigParams?.MaxDailySubVerticalBudget
        )
      : null;
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.SubVerticalName =
      subVerticalInput?.subvertical.SubVerticalName;
    mergeBuyerSubVerticalMonetaryCapacityConfigParams.AffiliateId =
      mergeBuyerSubVerticalMonetaryCapacityConfigParams.AffiliateId || null;

    saveBuyerSubVerticalMonetaryCapacity({
      variables: { mergeBuyerSubVerticalMonetaryCapacityConfigParams },
    }).then((response: any) => {
      if (
        mergeBuyerSubVerticalMonetaryCapacityConfigParams.BuyerSubVerticalMonetaryCapacityConfigId <
        1
      ) {
        eventTracker({ mergeBuyerSubVerticalMonetaryCapacityConfigParams });
        toast.success(
          "Buyer Subvertical Monetary Capacity created successfully."
        );
      } else toast.success("Buyer Subvertical Monetary updated successfully.");

      refetch && refetch();
      close();
    });
  };

  const onSubmit = (
    mergeBuyerSubVerticalMonetaryCapacityConfigParams: MergeBuyerSubVerticalMonetaryCapacityConfigInputType
  ) => {
    setDisableButtons(true);
    if (mergeBuyerSubVerticalMonetaryCapacityConfigParams.AffiliateId) {
      //check affiliateId if valid
      setSaveState("validating");
      isAffiliateIdActive({
        variables: {
          affiliateId:
            mergeBuyerSubVerticalMonetaryCapacityConfigParams.AffiliateId,
        },
      });
    } else {
      handleSaveBuyerSubVerticalMonetaryCapacity();
    }
    return false;
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <TextField
            required
            hidden
            inputRef={register({ valueAsNumber: true })}
            name="BuyerSubVerticalMonetaryCapacityConfigId"
            defaultValue={data?.BuyerSubVerticalMonetaryCapacityConfigId ?? -1}
          />
          <TextField
            required
            hidden
            inputRef={register({ valueAsNumber: true })}
            name="BuyerId"
            defaultValue={data?.BuyerId ?? buyerId}
          />
          <Grid item xs={12}>
            <TextField
              type="number"
              inputRef={register({
                pattern: {
                  value: /^[1-9]\d*$/s,
                  message: "Invalid Affiliate Id",
                },
                maxLength: {
                  value: 5,
                  message: "Invalid Affiliate Id",
                },
                validate: (value: string) => {
                  if (value)
                    return existingAffiliateIds?.find(
                      (data) =>
                        data.AffiliateId === parseInt(value) &&
                        data.SubVerticalName ===
                          subVerticalInput?.subvertical.SubVerticalName
                    )
                      ? `AffiliateId: ${value} is already in use`
                      : true;
                  // else
                  //   return existingAffiliateIds?.includes(null)
                  //     ? `AffiliateId: null is already in use`
                  //     : true;
                },
              })}
              name="AffiliateId"
              label="Affiliate Id"
              defaultValue={data?.AffiliateId ?? ""}
              error={errors.AffiliateId && true}
              helperText={errors.AffiliateId && errors.AffiliateId?.message}
              variant="outlined"
              // disabled={data?.AffiliateId}
              InputProps={{ readOnly: !!data?.AffiliateId }}
              // onBlur={() => {
              //   handleCheckAffililateId();
              // }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="sub-vertical-input"
              options={subVerticalOptions || []}
              getOptionLabel={(option) =>
                `${option.vertical?.VerticalName} - ${
                  option.subvertical?.SubVerticalName
                }${option.exists ? " (Used)" : ""}`
              }
              getOptionSelected={(option, value) =>
                option.subvertical?.SubVerticalName ===
                value?.subvertical?.SubVerticalName
              }
              getOptionDisabled={(option) => option.exists === true}
              value={subVerticalInput}
              onChange={(event: any, newValue: any) => {
                // console.log(inUsedList);
                setSubVerticalInput(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SubVertical Name"
                  variant="outlined"
                  error={errors.SubVerticalName ? true : false}
                  helperText={
                    errors.SubVerticalName && errors.SubVerticalName?.message
                  }
                  inputRef={register(formError.SubVerticalName)}
                  name="SubVerticalName"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register({
                max: {
                  value: 50000000,
                  message: "You exceeded the max limit.",
                },
              })}
              error={errors.MaxMonthlySubVerticalBudget && true}
              helperText={
                errors.MaxMonthlySubVerticalBudget &&
                errors.MaxMonthlySubVerticalBudget?.message
              }
              type="number"
              name="MaxMonthlySubVerticalBudget"
              label="Max Monthly Budget"
              defaultValue={data?.MaxMonthlySubVerticalBudget ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register({
                max: {
                  value: 50000000,
                  message: "You exceeded the max limit.",
                },
              })}
              error={errors.MaxWeeklySubVerticalBudget && true}
              helperText={
                errors.MaxWeeklySubVerticalBudget &&
                errors.MaxWeeklySubVerticalBudget?.message
              }
              type="number"
              name="MaxWeeklySubVerticalBudget"
              label="Max Weekly Budget"
              defaultValue={data?.MaxWeeklySubVerticalBudget ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register({
                max: {
                  value: 50000000,
                  message: "You exceeded the max limit.",
                },
              })}
              error={errors.MaxDailySubVerticalBudget && true}
              helperText={
                errors.MaxDailySubVerticalBudget &&
                errors.MaxDailySubVerticalBudget?.message
              }
              type="number"
              name="MaxDailySubVerticalBudget"
              label="Max Daily Budget"
              defaultValue={data?.MaxDailySubVerticalBudget ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? true}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Is Active"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
