import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import gql from "graphql-tag";
import { StatusIcon, useModal, ContentLoading } from "../../components";
import { useLazyQuery } from "@apollo/react-hooks";
import "react-table/react-table.css";
import styled from "styled-components";
import { Button, Grid, Paper } from "@material-ui/core";
import { TabsContainer, TabsContainerProps } from "../../components/tabs";
import { BuyerFormModal } from "./buyerModal";
import EditIcon from '@material-ui/icons/Edit';

import { BuyerGroupDetail } from "../buyergroup/buyerGroupDetail";
import { GET_BUYERS } from "../../common/models/buyers";
import {
  GetBuyers,
  GetBuyers_LDPConfigQueryGroup_Buyer,
} from "../../common/models/types/GetBuyers";
import { GET_CONTRACTS_SIMPLE } from "../../common/models/contracts";
import {
  GetContractsSimple,
  GetContractsSimple_LDPConfigQueryGroup_Contract,
} from "../../common/models/types/GetContractsSimple";

interface BuyerProps {
  buyerId: string;
  // buyerData: Array<GetAllBuyers_LDPConfigQueryGroup_Buyer>;
}

interface BuyerDetailsProps {
  details: GetBuyers_LDPConfigQueryGroup_Buyer;
  openModal: Function;
  closeModal: Function;
  setContent: Function;
  refetch: Function;
}

const BuyerDetails = ({
  details,
  openModal,
  closeModal,
  setContent,
  refetch,
}: BuyerDetailsProps) => {

  const [
    getContracts,
    { data, error, loading, called },
  ] = useLazyQuery<GetContractsSimple>(GET_CONTRACTS_SIMPLE);

  useEffect(() => {
    getContracts({
      variables: {
        where: `BuyerId = ${details.BuyerId}`
      }
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} key='leftcol'>
        <Style.Info>
          <Style.Item key='buyerId'>
            <span style={{ fontWeight: "bold" }}>BuyerId: </span>
            <span>{details.BuyerId}</span>
          </Style.Item>
          <Style.Item key='buyername'>
            <span style={{ fontWeight: "bold" }}>Buyer Name: </span>
            <span>{details.BuyerName}</span>
          </Style.Item>
          <Style.Item key='buyertype'>
            <span style={{ fontWeight: "bold" }}>Buyer Type: </span>
            <span>{details.BuyerType}</span>
          </Style.Item>
          <Style.Item key='buyeraccmngr'>
            <span style={{ fontWeight: "bold" }}>Account Manager Name: </span>
            <span>{details.AccountManagerName}</span>
          </Style.Item>
          <Style.Item key='buyercontactlink'>
            <span style={{ fontWeight: "bold" }}>BuyerContactLinkId: </span>
            <span>{details.BuyerContactLinkId}</span>
          </Style.Item>
          <Style.Item key='buyeruserid'>
            <span style={{ fontWeight: "bold" }}>UserId: </span>
            <span>{details.UserId}</span>
          </Style.Item>
          <Style.Item key='createddate'>
            <span style={{ fontWeight: "bold" }}>CreatedDate: </span>
            <span>{details.CreatedDate}</span>
          </Style.Item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              openModal({
                title: "Edit Buyer",
                icon: <EditIcon />,
                iconColor: "green",
                content: (
                  <BuyerFormModal
                    action={"edit"}
                    data={details}
                    close={closeModal}
                    refetch={refetch}
                  />
                ),
              });
            }}
          >
            Edit Buyer
          </Button>
        </Style.Info>
      </Grid>
      <Grid item xs={6} key='rightcol'>
        {loading && <ContentLoading showTip={true} />}
        <Style.ContractItem key='item0'>
          <span></span>
          <span></span>
          <span style={{ fontWeight: "bold" }}>Contracts: </span>
        </Style.ContractItem>
        {!loading && data && data.LDPConfigQueryGroup?.Contract?.map((contract, i) => (
          <Style.ContractItem key={`item${i}`}>
            <span>
              <StatusIcon value={contract?.IsActive} />
            </span>
            <span style={{ fontWeight: "bold" }}>
              <a href={`/contracts/${contract?.ContractId}/${encodeURIComponent(contract?.ContractName)}`} target="_blank">{contract?.ContractId}</a>
            </span>
            <span style={{ fontWeight: "bold" }}>
              <a href={`/contracts/${contract?.ContractId}/${encodeURIComponent(contract?.ContractName)}`} target="_blank">{contract?.ContractName}</a>
            </span>
          </Style.ContractItem>
        ))}
      </Grid>
    </Grid>
  );
};

const ViewBuyers = (props: BuyerProps) => {
  const { buyerId } = props;
  const [buyerTabs, setBuyerTabs] = useState<TabsContainerProps["tabs"]>([]);
  const { Modal, closeModal, openModal, setContent } = useModal();
  const [getBuyer, { data, error, loading, called, refetch }] = useLazyQuery<
    GetBuyers
  >(GET_BUYERS);

  useEffect(() => {
    if (buyerId) {
      getBuyer({
        variables: { where: `BuyerId = ${buyerId}` },
      });
    }
  }, [buyerId]);

  return (
    <Paper style={Style.Paper}>
      {data ? (
        <BuyerDetails
          details={data?.LDPConfigQueryGroup?.Buyer[0]}
          openModal={openModal}
          closeModal={closeModal}
          setContent={setContent}
          refetch={refetch}
        />
      ) : (
        <ContentLoading showTip={true} />
      )}
      <Modal />
    </Paper>
  );
};

export default ViewBuyers;

const Style = {
  Paper: {
    padding: "0px",
    margin: "20px 0px",
    width: "100%",
  },
  Details: styled.span`
    margin-left: 10px;
  `,
  Info: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 5px 0px;
    overflow: hidden;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 10px;
    span {
      width: 50%;
      text-align: left;
      :first-child {
        text-align: right;
        margin-right: 20px;
      }
    }
  `,
  ContractItem: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px;
    span {
      width: 60%;
      text-align: left;
      :first-child {
        width: 10%;
        text-align: right;
        margin-right: 20px;
      }
      :nth-child(2) {
        width: 10%;
        text-align: right;
        margin-right: 20px;
      }
    }
  `,
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
