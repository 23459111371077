import gql from "graphql-tag";

export const GET_ACCOUNT_MANAGER_LIST = gql`
  query GetAccountManagerList {
    LDPConfigQueryGroup {
      GetAccountManagers {
        AccountManagerName
      }
    }
  }
`;