import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { MERGE_BUYER_MONETARY_CAPACITY } from "../../common/models/buyerCapacity";
import { GET_IS_AFFILIATE_ID_ACTIVE } from "../../common/models/contractMonetaryCapacity";
import { BuyerMonetaryCapacityConfig_LDPConfigQueryGroup_BuyerMonetaryCapacityConfig } from "../../common/models/types/BuyerMonetaryCapacityConfig";
import { GetIsAffiliateIdActive } from "../../common/models/types/GetIsAffiliateIdActive";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { eventTracker as tracker } from '../../components/tracker';
import { MergeBuyerMonetaryCapacityConfigInputType } from "../../types/graphql-global-types";

interface BuyerMonetaryCapacityFormModalProps {
  buyerId: any;
  data: BuyerMonetaryCapacityConfig_LDPConfigQueryGroup_BuyerMonetaryCapacityConfig | null;
  action: string;
  close: Function;
  refetch: Function;  
  existingAffiliateIds: (number | null)[];
}

// const formError = {};
export const BuyerMonetaryCapacityFormModal = ({
  action,
  buyerId,
  data,
  close,
  refetch,  
  existingAffiliateIds,
}: BuyerMonetaryCapacityFormModalProps) => {     
  const googleUserMeta = GoogleUserMeta();
  const { register, handleSubmit, setValue, errors, watch, setError, getValues } = useForm<MergeBuyerMonetaryCapacityConfigInputType>();  
  const classes = useStyles();  

  const [
    getIsAffiliateIdActive,
    { data: isAffiliateIdActiveData, loading: isAffiliateIdActiveLoading },
  ] = useLazyQuery<GetIsAffiliateIdActive>(
    GET_IS_AFFILIATE_ID_ACTIVE,
    { fetchPolicy: "network-only" }
  );

  const [saveState, setSaveState] = useState<'preparing' | 'validating' | 'validated' | 'ready'>('preparing');

  const watchAffiliateId = watch('AffiliateId');

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  
  const eventTracker = ({ data }: any) => {
    const blockers: any = { '@': '[at]', '.': '[dot]' };
    tracker({
      name: "CreateNewBuyerMonetaryCapacity",
      caption: "Track Create New Buyer Monetary Capacity",
      values: {
        ...data,
        email: googleUserMeta?.email?.replace(/\@|\./g, it => blockers[it]) ?? null
      }
    });
  }
  const [saveBuyerMonetaryCapacity] = useMutation(MERGE_BUYER_MONETARY_CAPACITY);

  const handleSaveBuyerMonetaryCapacity = async () => {
    const mergeBuyerMonetaryCapacityConfigParams = getValues();

    mergeBuyerMonetaryCapacityConfigParams.UserId = googleUserMeta?.email || '';
    mergeBuyerMonetaryCapacityConfigParams.BuyerMonetaryCapacityConfigId = parseInt(`${mergeBuyerMonetaryCapacityConfigParams?.BuyerMonetaryCapacityConfigId}`);
    mergeBuyerMonetaryCapacityConfigParams.BuyerId = parseInt(`${mergeBuyerMonetaryCapacityConfigParams?.BuyerId}`);
    mergeBuyerMonetaryCapacityConfigParams.MaxMonthlyBudget = mergeBuyerMonetaryCapacityConfigParams?.MaxMonthlyBudget ? parseInt(mergeBuyerMonetaryCapacityConfigParams?.MaxMonthlyBudget) : null;
    mergeBuyerMonetaryCapacityConfigParams.MaxWeeklyBudget = mergeBuyerMonetaryCapacityConfigParams?.MaxWeeklyBudget ? parseInt(mergeBuyerMonetaryCapacityConfigParams?.MaxWeeklyBudget) : null;
    mergeBuyerMonetaryCapacityConfigParams.MaxDailyBudget = mergeBuyerMonetaryCapacityConfigParams?.MaxDailyBudget ? parseInt(mergeBuyerMonetaryCapacityConfigParams?.MaxDailyBudget) : null;
    mergeBuyerMonetaryCapacityConfigParams.AffiliateId = mergeBuyerMonetaryCapacityConfigParams?.AffiliateId ? parseInt(`${mergeBuyerMonetaryCapacityConfigParams?.AffiliateId}`) : null;


    const result = await saveBuyerMonetaryCapacity({
      variables: { mergeBuyerMonetaryCapacityConfigParams }
    }).then((response: any) => {
      if (mergeBuyerMonetaryCapacityConfigParams.BuyerMonetaryCapacityConfigId < 1) {
        eventTracker({ mergeBuyerMonetaryCapacityConfigParams });
        toast.success("Buyer Capacity created successfully.");
      }
      else toast.success("Buyer Capacity updated successfully.");

      refetch && refetch();
      close();
    }).catch((error) => {
      toast.error('Error: Buyer monetary capacity already exists.');
    });

    setDisableButtons(false);
  }


  const onSubmit = (mergeBuyerMonetaryCapacityConfigParams: MergeBuyerMonetaryCapacityConfigInputType) => {
    setDisableButtons(true);
    if(mergeBuyerMonetaryCapacityConfigParams.AffiliateId){
      //check affiliateId if valid
      setSaveState('validating');
      getIsAffiliateIdActive({
        variables:{
          affiliateId: mergeBuyerMonetaryCapacityConfigParams.AffiliateId
        }
      })
    } else {
      handleSaveBuyerMonetaryCapacity();
    }
    return false;
  }

  useEffect(()=>{
    if(saveState === 'validating' && !isAffiliateIdActiveLoading && isAffiliateIdActiveData){ //this step ensures that API AffiliateId validation is complete before setting field errors
      setSaveState('validated');
    } else if(saveState === 'validated' && watchAffiliateId && isAffiliateIdActiveData){
      if(isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive){
        setSaveState('ready');
      } else {
        setSaveState('preparing');
        setError('AffiliateId', { type:'error', message: 'AffiliateId is inactive, please set a different value'});
        setDisableButtons(false);
      }
    } else if(saveState === 'ready' && watchAffiliateId){
      handleSaveBuyerMonetaryCapacity();
    }
  },[isAffiliateIdActiveLoading, saveState, isAffiliateIdActiveData, watchAffiliateId]);


  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <TextField
            required
            hidden
            inputRef={register}
            name="BuyerMonetaryCapacityConfigId"
            defaultValue={data?.BuyerMonetaryCapacityConfigId ?? -1}
          />
          <TextField
            required
            hidden
            inputRef={register}
            name="BuyerId"
            defaultValue={data?.BuyerId ?? buyerId}
          />
           <Grid item xs={12}>
            <TextField
              inputRef={register({
                pattern: {
                  value: /^[1-9]\d*$/s, 
                  message: "Invalid Affiliate Id"
                },
                maxLength: {
                  value: 5,
                  message: "Invalid Affiliate Id"
                },
                validate: (value: string) => {
                 if(value) return existingAffiliateIds.includes(parseInt(value)) ? `AffiliateId: ${value} is already in use` : true;
                 else return existingAffiliateIds.includes(null) ? `AffiliateId: null is already in use` : true;
                }
              })}
              error={errors.AffiliateId && true}
              helperText={!isAffiliateIdActiveLoading && errors.AffiliateId && errors.AffiliateId?.message}
              name="AffiliateId"
              label="AffiliateId"
              defaultValue={data?.AffiliateId ?? ""}
              variant="outlined"
              type="number"
              disabled={(data?.AffiliateId || saveState === 'validating') ? true : false}
            />
          </Grid>
          <Grid item xs={12}>            
            <TextField
              inputRef={register({
                max: {
                  value: 50000000,
                  message: "You exceeded the max limit.",
                },
              })}
              error={errors.MaxMonthlyBudget && true}
              helperText={
                errors.MaxMonthlyBudget &&
                errors.MaxMonthlyBudget?.message
              }
              type="number"
              name="MaxMonthlyBudget"
              label="Max Monthly Budget"
              defaultValue={data?.MaxMonthlyBudget ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={12}>            
            <TextField
              inputRef={register({
                max: {
                  value: 50000000,
                  message: "You exceeded the max limit.",
                },
              })}
              error={errors.MaxWeeklyBudget && true}
              helperText={
                errors.MaxWeeklyBudget &&
                errors.MaxWeeklyBudget?.message
              }
              type="number"
              name="MaxWeeklyBudget"
              label="Max Weekly Budget"
              defaultValue={data?.MaxWeeklyBudget ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>            
            <TextField
              inputRef={register({
                max: {
                  value: 50000000,
                  message: "You exceeded the max limit.",
                },
              })}
              error={errors.MaxDailyBudget && true}
              helperText={
                errors.MaxDailyBudget &&
                errors.MaxDailyBudget?.message
              }
              type="number"
              name="MaxDailyBudget"
              label="Max Daily Budget"
              defaultValue={data?.MaxDailyBudget ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? true}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Is Active"
            />
          </Grid>

          <Grid item xs={12}>
              <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
          
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
