import React, { useEffect, useState } from 'react'
import { TabProps, TabsContainer } from '../tabs';
import { connect } from 'react-redux';
import { RootState } from '../../state';
import { updateTabContainers } from '../../state/tabsReducer';
import BuyerAffiliatePriceHistory from './buyerAffiliatePriceDrillin';

interface BuyerAffiliatePriceHistoryTabContainerProps {
    buyerId: string;
    dispatch?: Function;
    tabSections: RootState['tabsSection'];
}

const BuyerAffiliatePriceHistoryTabContainer = ({buyerId, data, tabSections, dispatch}:BuyerAffiliatePriceHistoryTabContainerProps) => {
    const TAB_CONTAINER = `buyer-${buyerId}-affiliate-price-history-${data?.Price}`;

    const [tabFocus, setTabFocus] = useState<number>(0);
    const [tabs, setTabs] = useState<TabProps[]>([]);
    const [tabsInitialized, setTabsInitialized] = useState(false);

    const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
        dispatch && dispatch(
          updateTabContainers({
            [props.tabsContainerId]: {
              tabFocus: props.focus,
              tabs: props.tabs,
            }
          })
        );
      };

    const handleCloseTab = (tabs: TabProps[]) => {
      dispatch && dispatch(
        updateTabContainers({
          [TAB_CONTAINER]: {
            tabFocus: (tabs.length - 1),
            tabs: tabs,
          }
        })
      );
    };

    useEffect(() => {
        if (! tabSections?.tabContainers[TAB_CONTAINER]) {
          let initialTabContainerFocus = 0;
    
          let initialTabs: TabProps[] = [
            {
              title: "Details",
              tabId: `buyer-${buyerId}-${TAB_CONTAINER}-main`,
              //@ts-ignore
              content: <BuyerAffiliatePriceHistory buyerId={buyerId} data={data} tabContainer={TAB_CONTAINER}/>,
            },
          ];
    
          /* if(process.env.BRANCH_NAME === "production" || process.env.GATSBY_BRANCH === "production"){
            const isQATesting = localStorage.getItem('qaTesting') || false;
    
            if (! isQATesting)
              initialTabs.pop(); // disable filters on production
          } */
    
          const initialTabContainer = {
            [TAB_CONTAINER]: {
              tabFocus: initialTabContainerFocus,
              tabs: initialTabs,
            }
          };
    
          dispatch && dispatch(updateTabContainers(initialTabContainer));
        } else {
          if (tabSections.tabContainers[TAB_CONTAINER]) {
            setTabs(tabSections.tabContainers[TAB_CONTAINER].tabs);
            setTabFocus(tabSections.tabContainers[TAB_CONTAINER].tabFocus);
            setTabsInitialized(true);
          }
        }
      }, [tabSections]);

  return (
    <div>
      <TabsContainer
        tabs={tabs}
        onCloseTab={setTabs}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
    </div>
  )
}

const BuyerAffiliatePriceHistoryTabContainerWrapper = connect((state: RootState) => ({
    tabSections: state.tabsSection,
  }), null)(BuyerAffiliatePriceHistoryTabContainer);
  
export default BuyerAffiliatePriceHistoryTabContainerWrapper
