import gql from "graphql-tag";

export const GET_LEAD_FIELD_DATA = gql`
   query GetLeadFieldData($where: String) {
      LDPIngestQueryGroup {
         LeadDataLookup(limit:9999, where: $where) {
            CreatedDate
            LeadDataColumnName
            LeadDataLookupId
            LeadDataValue
            ModifiedDate
            SubVerticalId
            VerticalId
         }
      }
   }
`;  

export const GET_LEAD_DETAIL = gql`
   query GetLeadDetail(
      $Uuid: String
      $ExternalId: String
      $ExternalLeadId: String
      $Email: String
      $PhoneNumber: String
      $VerticalId: ID
      $SubVerticalId: ID
      $LeadDataId: ID
      $FetchEDW: Boolean
   ) {
      LDPIngestQueryGroup {
         GetLeadDetail(
            Uuid: $Uuid
            ExternalId: $ExternalId
            ExternalLeadId: $ExternalLeadId
            Email: $Email
            PhoneNumber: $PhoneNumber
            VerticalId: $VerticalId
            SubVerticalId: $SubVerticalId
            LeadDataId: $LeadDataId
            FetchEDW: $FetchEDW
         )
      }
   }
`;  

export const GET_LEAD_CONFIG_HISTORY = gql`
   query GetHistoryBuyerSaleQueuePriceChange ($signupId: Long!) {
      LDPConfigQueryGroup {
         GetHistoryBuyerSaleQueuePriceChange(SignupId: $signupId) {        
            Amount
            BuyerContractId
            CreatedDate
            IsPriceChange
            LdpBuyername
            ModifiedDate
            SignupId
            UserId
         }
      }
   }
`;
