import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Add, AddCircle, Edit as EditIcon, FileCopy, History as HistoryIcon } from '@material-ui/icons';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { GET_BUYER_FILTERS } from "../../common/models/buyerFilters";
import { GetBuyerFilters, GetBuyerFiltersVariables } from "../../common/models/types/GetBuyerFilters";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { ContentLoading, LDPUIDataTable, StatusIcon, useModal } from "../../components";
import { booleanRadioFilter, booleanToggleFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";
import { RootState } from "../../state";
import { updateBuyerDetailForms } from "../../state/buyerSectionReducer";
import { dockForm, openForm, resetForm } from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { BuyerFilterModal } from "./buyerFilterModal";
import { EllipsisTooltip, useEllipsisStyles } from "../../components/tooltip";
/* import BuyerCloneFilterFormModal from "./buyerCloneFilterFormModal"; */


interface BuyerFilterListProps {
  buyerId: string;
  dispatch: Function;
  formsSection: any;
}

const BuyerFilterList = ({
  buyerId,
  dispatch,
  formsSection,
}: BuyerFilterListProps) => {
  const [getBuyerFilterList, { data, error, loading, called, refetch }] = useLazyQuery<GetBuyerFilters, GetBuyerFiltersVariables>(GET_BUYER_FILTERS, { fetchPolicy: 'network-only' });

  const { Modal, closeModal, openModal, setContent } = useModal();
  const classes = useEllipsisStyles();

  useEffect(() => {
    getBuyerFilterList({
      variables: {
        buyerId: buyerId
      }
    });
  }, []);

  const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
    if (rowData) {
      let newJson: any = {};
      muiColumns.map((column: any, key: number) => {
        newJson[column.name] = rowData[key];
      });

      return newJson;
    } else return null;
  };

  const columnsMUI = [
    {
      name: "BuyerFilterId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">Edit Buyer Filter</Typography>
                </React.Fragment>
              }>
                <IconButton onClick={() => {
                  openModal({
                    title: "Edit Buyer Filter",
                    icon: <EditIcon />,
                    iconColor: "violet",
                    content: (
                      <BuyerFilterModal
                        action="edit"
                        buyerId={buyerId}
                        data={mapRowDataToColumns(columnsMUI, tableMeta.rowData)}
                        close={closeModal}
                        refetch={() => {
                          getBuyerFilterList({
                            variables: {
                              buyerId: buyerId
                            }
                          });
                        }}
                      />
                    ),
                  });
                }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">History</Typography>
                </React.Fragment>
              }>
                <IconButton onClick={() => {
                  const HISTORY_TAB_ID = `buyer-filter-${value}-history`;

                  const formProps = {
                    formId: HISTORY_TAB_ID,
                    formTitle: `Buyer Filter ID#${value} History`,
                    formIcon: <HistoryIcon />,
                    formComponent: <HistoryModal ConfigName="BuyerFilter" ConfigId={value} TabContainerId={`history-buyer-filter-${value}-list-tabs`} />,
                    formData: { ConfigName: "BuyerFilter", ConfigId: value, ...tableMeta.tableData[tableMeta.rowIndex] },
                    formProps: {
                      closeTab: () => dispatch && dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                    }
                  };

                  dispatch && dispatch(updateBuyerDetailForms({ buyerId: `buyer-${buyerId}`, formIds: [HISTORY_TAB_ID] }));

                  dispatch && dispatch(
                    formsSection?.formContainers[HISTORY_TAB_ID]
                      && formsSection?.formContainers[HISTORY_TAB_ID].isLastOpenedDocked ?
                      dockForm(formProps) : openForm(formProps)
                  );
                }}>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>

          );
        },
      },
    },
    {
      name: "BuyerFilterId",
      label: "Filter ID",
      options: {
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: "BuyerFilterName",
      label: "Filter Name",
      options: {
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: "FieldName",
      label: "Field Name",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "Operation",
      label: "Operation",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "Delimiter",
      label: "Delimiter",
      options: {
        viewColumns: false,
        filter: false,
        display: false,
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        customBodyRender: (value: any, tableMeta: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span
              className={`${classes.ellipsisColumn} ${classes.ellipsisColumnFilterValue}`}
            >
              {value}
            </span>
          </EllipsisTooltip>
        ),
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: []
      },
    },
    {
      name: "VerticalName",
      label: "Vertical",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "SubVerticalName",
      label: "SubVertical",
      options: {
        viewColumns: true,
        filter: true,
      },
    },

    {
      name: "UserId",
      label: "User ID",
      options: {
        viewColumns: true,
        filter: true,
        ...stringColumnFilter,
        filterList: []
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
        //...createDateFilter()
      }
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        customBodyRender: (value: any) => {
          return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
        //...createDateFilter()
      }
    },
    {
      name: "ExternalProcedureName",
      label: "External Procedure",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "ProcedureParameterName",
      label: "Procedure Parameter",
      options: {
        filter: true,
        display: false,
      },
    },

    {
      name: "ProcedureDescription",
      label: "Procedure Description",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "IsDecimal",
      label: "IsDecimal",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        display: false,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`IsDecimal: ${v ? "Yes" : "No"}`) },
        ...booleanRadioFilter("Is Decimal"),
        filterList: []
      },
    },
    {
      name: "IsList",
      label: "IsList",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        display: false,
        ...booleanRadioFilter("Is List"),
        filterList: []
      },
    },
    {
      name: "IsNumeric",
      label: "IsNumeric",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        display: false,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`IsNumeric: ${v ? "Yes" : "No"}`) },
        ...booleanRadioFilter("Is Numeric"),
        filterList: []
      },
    },


  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>&nbsp;</Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <Grid container spacing={1} justify="flex-end">
            {/* <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<FileCopy />}
                  onClick={() => {
                    openModal({
                      title: "Clone Buyer Filter",
                      icon: <FileCopy />,
                      iconColor: "orange",
                      content: (
                        <BuyerCloneFilterFormModal
                          buyerId={buyerId}
                          onClose={closeModal}
                          onReload={() => {
                            getBuyerFilterList({
                              variables: {
                                buyerId: buyerId
                              }
                            });
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Clone Filter
                </Button>
              </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "Add New Buyer Filter",
                    icon: <Add />,
                    iconColor: "green",
                    content: (
                      <BuyerFilterModal
                        action="create"
                        buyerId={buyerId}
                        data={mapRowDataToColumns(columnsMUI, {})}
                        close={closeModal}
                        refetch={() => {
                          getBuyerFilterList({
                            variables: {
                              buyerId: buyerId
                            }
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                Add Buyer Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!data || loading ? <ContentLoading showTip={true} /> : (
          <LDPUIDataTable
            ldpTableId={`buyer-${buyerId}-filters-list`}
            restoreFilters={true}
            title={"Buyer Filters"}
            data={data?.LDPConfigQueryGroup?.GetBuyerFilters ?? []}
            columns={columnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  formsSection: state.formsSection,
}), null)(BuyerFilterList);
