import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import {
  default as EditIcon,
  default as LaunchIcon,
} from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import { MUIDataTableColumnDef } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DEL_BUYER_AFFILIATE_PRICE,
  GET_BUYER_AFFILIATE_PRICE,
} from "../../common/models/buyerAffiliatePrice";
import {
  DelBuyerAffiliatePrice,
  DelBuyerAffiliatePriceVariables,
} from "../../common/models/types/DelBuyerAffiliatePrice";
import {
  GetBuyerAffiliatePrice,
  GetBuyerAffiliatePriceVariables,
} from "../../common/models/types/GetBuyerAffiliatePrice";
import { dateToPreferredTimezone } from "../../common/utils/date";
import {
  queryLoad,
  StatusIcon,
  useConfirmation,
  useModal,
} from "../../components";
import { BuyerAffiliatePriceHistory } from "../../components/buyerAffiliatePriceHistory";
import {
  booleanRadioFilter,
  numberColumnFilter,
  stringColumnFilter,
} from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { RootState } from "../../state";
import { FormStateProps } from "../../state/formReducer";
import { BuyerAffiliatePriceFormModal } from "./buyerAffiliatePriceFormModal";
import { updateRecordData } from "../../state/generalStateReducer";

interface BuyerAffiliatePriceProps {
  buyerId: number;
  buyerTabs: any;
  dispatch: Function;
  buyerForms: FormStateProps;
}

export interface CollectiveAffiliatePriceData {
  ModifiedDate: string;
  Affiliates: {
    BuyerAffiliatePriceId: number | null;
    AffiliateId: number;
  }[];
  AffiliateId: string;
  Price: number;
  IsActive: boolean;
  UserId?: string | null;
  Notes?: string | null;
}

interface AffiliatePriceData
  extends Omit<CollectiveAffiliatePriceData, "Affiliates"> {
  AffiliateId: string;
}

const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
  if (rowData) {
    let newJson: any = {};
    muiColumns.map((column: any, key: number) => {
      if (column.name) newJson[column.name] = rowData[key];
    });
    return newJson;
  } else return null;
};

//Update props types once view requirements are finalized
const BuyerAffiliatePrice = ({
  buyerId,
  dispatch,
  buyerForms,
}: BuyerAffiliatePriceProps) => {
  const LDP_BUYER_AFF_PRICE_TABLE_ID = `buyer-affiliate-price-${buyerId}`;

  const [
    getBuyerAffiliatePrice,
    {
      data: priceData,
      loading: priceLoading,
      error: priceError,
      refetch: priceRefetch,
    },
  ] = useLazyQuery<GetBuyerAffiliatePrice, GetBuyerAffiliatePriceVariables>(
    GET_BUYER_AFFILIATE_PRICE,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    delBuyerAffiliatePrice,
    { data: delResult, error, loading },
  ] = useMutation<DelBuyerAffiliatePrice, DelBuyerAffiliatePriceVariables>(
    DEL_BUYER_AFFILIATE_PRICE
  );

  const {
    Modal: Confirmation,
    closeModal: closeConfirmation,
    useModal: setConfirmation,
  } = useConfirmation();

  const [priceListData, setPriceListData] = useState<
    CollectiveAffiliatePriceData[]
  >([]);

  useEffect(() => {
    getBuyerAffiliatePrice({
      variables: { where: `BuyerId = ${buyerId}`, limit: 999 },
    });
  }, []);

  useEffect(() => {
    if (priceData) {
      const byPrice: { 
        [price: number]: CollectiveAffiliatePriceData;
      } = {};

      for (const affiliatePrice of priceData.LDPConfigQueryGroup
        ?.GetBuyerAffiliatePrice || []) {
        if (!byPrice[affiliatePrice?.Price]) {
          byPrice[affiliatePrice?.Price] = {
            ModifiedDate: affiliatePrice?.ModifiedDate,
            Affiliates: [
              {
                BuyerAffiliatePriceId: affiliatePrice?.BuyerAffiliatePriceId,
                // @ts-ignore
                AffiliateId: affiliatePrice?.AffiliateId,
              },
            ],
            AffiliateId: "",
            Price: affiliatePrice?.Price,
            IsActive: affiliatePrice?.IsActive || false,
            UserId: affiliatePrice?.UserId,
            Notes: affiliatePrice?.Notes,
          };
        } else {
          byPrice[affiliatePrice?.Price].Affiliates.push({
            BuyerAffiliatePriceId: affiliatePrice?.BuyerAffiliatePriceId,
            // @ts-ignore
            AffiliateId: affiliatePrice?.AffiliateId,
          });

          if (
            affiliatePrice?.ModifiedDate >
            byPrice[affiliatePrice?.Price].ModifiedDate
          ) {
            byPrice[affiliatePrice?.Price].ModifiedDate =
              affiliatePrice?.ModifiedDate;
            byPrice[affiliatePrice?.Price].UserId = affiliatePrice?.UserId;
            byPrice[affiliatePrice?.Price].Notes =
              affiliatePrice?.Notes || byPrice[affiliatePrice?.Price].Notes;
          }
        }

        byPrice[affiliatePrice?.Price].AffiliateId = byPrice[
          affiliatePrice?.Price
        ].Affiliates.map((a) => a.AffiliateId)
          .sort()
          .join(", ");
      }

      setPriceListData(Object.values(byPrice));
    } else if (priceError) {
      setPriceListData([]);
    }
  }, [priceData, priceError]);

  const { Modal, closeModal, openModal } = useModal();

  const AffiliatePriceColumnsMUI: MUIDataTableColumnDef[] = [
    {
      name: "DataEditCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const data = mapRowDataToColumns(
            AffiliatePriceColumnsMUI,
            tableMeta.rowData
          );
          return (
            <>
              <IconButton
                onClick={() => {
                  if (priceData)
                    openModal({
                      title: `Edit Affiliate Price`,
                      icon: <EditIcon />,
                      iconColor: "orange",
                      content: (
                        <BuyerAffiliatePriceFormModal
                          action={"edit"}
                          buyerId={buyerId}
                          data={data}
                          close={closeModal}
                          refetch={priceRefetch}
                        />
                      ),
                    });
                }}
              >
                <LaunchIcon />
              </IconButton>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    const rowData = mapRowDataToColumns(
                      AffiliatePriceColumnsMUI,
                      tableMeta.rowData
                    )
                    dispatch(updateRecordData(`Buyer#${buyerId}_AffiliatePriceData`, rowData.Affiliates));

                    openModal({
                      title: `Buyer Affiliate Price History BuyerId:[${buyerId}] Price:[${tableMeta.rowData[4].toFixed(
                        2
                      )}]`,
                      icon: <HistoryIcon />,
                      iconColor: "orange",
                      content: (
                        //@ts-ignore
                        <BuyerAffiliatePriceHistory
                          buyerId={buyerId.toString()}
                        />
                      ),
                    });
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },

    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "Affiliates",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: "AffiliateId",
      label: "Affiliate ID",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value) => {
          return value;
        },
      },
    },

    {
      name: "Price",
      label: "Price",
      options: {
        customBodyRender: (value: any) => {
          return `${Number(value).toFixed(2)}`;
        },
        ...numberColumnFilter,
      },
    },

    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: [],
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {
        ...stringColumnFilter,
      },
    },

    /* {
      name: "BuyerId",
      label: "Buyer ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    }, */

    /* {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
        display: false,
      },
    }, */

    {
      name: "Notes",
      label: "Notes",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "ModifiedDate",
      direction: "desc",
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  if (priceData)
                    openModal({
                      title: `Add New Affiliate Price`,
                      icon: <AddCircle />,
                      iconColor: "orange",
                      content: (
                        <BuyerAffiliatePriceFormModal
                          action={"create"}
                          data={null}
                          listData={priceData}
                          buyerId={buyerId}
                          close={closeModal}
                          refetch={priceRefetch}
                        />
                      ),
                    });
                }}
              >
                Create Affiliate Price
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!priceLoading], [priceError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_BUYER_AFF_PRICE_TABLE_ID}
            restoreFilters={true}
            title={"Affiliate Price"}
            data={priceListData}
            columns={AffiliatePriceColumnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
      <Confirmation />
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    buyerForms: state.formsSection,
  }),
  null
)(BuyerAffiliatePrice);
