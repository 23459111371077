import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  GET_BUYER_SUBVERTICAL_CAPACITY,
  SAVE_BUYER_SUBVERTICAL_CAPACITY,
} from "../../common/models/buyerCapacity";
import { GET_IS_AFFILIATE_ID_ACTIVE } from "../../common/models/contractMonetaryCapacity";
import {
  BuyerSubVerticalCapacity,
  BuyerSubVerticalCapacityVariables,
  BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity,
} from "../../common/models/types/BuyerSubVerticalCapacity";
import {
  GetSubVertical,
  GetSubVertical_LDPConfigQueryGroup_SubVertical,
} from "../../common/models/types/GetSubVertical";
import {
  GetVertical,
  GetVertical_LDPConfigQueryGroup_Vertical,
} from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { queryLoad } from "../../components";

interface SubVerticalOption {
  vertical: GetVertical_LDPConfigQueryGroup_Vertical;
  subvertical: GetSubVertical_LDPConfigQueryGroup_SubVertical;
  exists: boolean;
}

interface BuyerSubVerticalCapacityInput {
  BuyerId: any;
  BuyerSubVerticalCapacityId: any;
  DailyMaxCapacity: number | null;
  IsActive: boolean | null;
  MonthlyMaxCapacity: number | null;
  Notes: string | null;
  UserId: string | null;
  WeeklyMaxCapacity: number | null;
  SubVerticalName: string | null;
  AffiliateId: number | null;
}

interface BuyerCapacityFormModalProps {
  buyerCapId?: number | null;
  buyerId?: number | string | null;
  action: string;
  close: Function;
  refetch?: () => void;
  dispatch: Function;
  googleUserMeta?: GoogleUserMetaProp;
  existingAffiliateIds?: {
    AffiliateId: number | null;
    SubVerticalName: string | null;
  }[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

const formError = {
  SubVerticalName: {
    required: {
      value: true,
      message: "SubVertical Name is required.",
    },
  },
  DailyMaxCapacity: {
    min: {
      value: 0,
      message: "Minimum value is 0",
    },
    pattern: {
      value: /^(0|[1-9]\d*)(\.\d+)?$/,
      message: "Daily Max Capacity allows positive numbers only.",
    },
  },
  WeeklyMaxCapacity: {
    min: {
      value: 0,
      message: "Minimum value is 0",
    },
    pattern: {
      value: /^(0|[1-9]\d*)(\.\d+)?$/,
      message: "Weekly Max Capacity allows positive numbers only.",
    },
  },
  MonthlyMaxCapacity: {
    min: {
      value: 0,
      message: "Minimum value is 0",
    },
    pattern: {
      value: /^(0|[1-9]\d*)(\.\d+)?$/,
      message: "Monthly Max Capacity allows positive numbers only.",
    },
  },
  Notes: {
    maxLength: {
      value: 256,
      message: "Max characters is 256 only.",
    },
  },
};

const WrappedBuyerSubVerticalCapacityFormModal = ({
  action,
  buyerCapId,
  buyerId,
  close,
  refetch,
  dispatch,
  existingAffiliateIds,
}: BuyerCapacityFormModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    errors,
    setError,
    getValues,
  } = useForm<BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity>();

  const watchAffiliateId = watch("AffiliateId");
  const classes = useStyles();
  const [
    getBuyerSubVerticalCapacities,
    { data, error, loading, called },
  ] = useLazyQuery<BuyerSubVerticalCapacity, BuyerSubVerticalCapacityVariables>(
    GET_BUYER_SUBVERTICAL_CAPACITY,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);
  const [
    subVerticalInput,
    setSubVerticalInput,
  ] = useState<SubVerticalOption | null>(null);
  const [subVerticalOptions, setSubVerticalOptions] = useState<
    SubVerticalOption[]
  >([]);
  const [saveBuyerSubVerticalCapacity] = useMutation(
    SAVE_BUYER_SUBVERTICAL_CAPACITY
  );

  const [
    isAffiliateIdActive,
    { data: isAffiliateIdActiveData, loading: isAffiliateIdActiveLoading },
  ] = useLazyQuery(GET_IS_AFFILIATE_ID_ACTIVE);

  const [
    buyerCapDetails,
    setBuyerCapDetails,
  ] = useState<BuyerSubVerticalCapacityInput>({
    BuyerId: buyerId,
    BuyerSubVerticalCapacityId: 0,
    DailyMaxCapacity: null,
    IsActive: true,
    MonthlyMaxCapacity: null,
    Notes: "",
    UserId: "",
    WeeklyMaxCapacity: null,
    SubVerticalName: "",
    AffiliateId: null,
  });
  const [buyerCapLoading, setBuyerCapLoading] = useState(true);
  const [touched, setTouched] = useState(false);
  const [notes, setNotes] = useState<string | null | undefined>("");

  useEffect(() => {
    if (action != "edit") {
      setBuyerCapLoading(false);
    }
  }, [action]);

  useEffect(() => {
    getBuyerSubVerticalCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`,
      },
    });
  }, []);

  useEffect(() => {
    getAllVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [data, error]);

  useEffect(() => {
    getAllSubVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [verticalData, verticalError]);

  const processInput = (
    input: string | number | null | undefined
  ): number | null => {
    // Check if the input is null, undefined, or an empty string
    if (input === null || input === undefined || input === "") {
      return null;
    }

    // Convert the input to a number and return
    return Number(input);
  };

  useEffect(() => {
    if (data && verticalData && subVerticalData) {
      const buyerCapSVCDataDetails = data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity?.find(
        (details) => details?.BuyerSubVerticalCapacityId === buyerCapId
      );

      const options: SubVerticalOption[] = [];
      for (const sv of subVerticalData.LDPConfigQueryGroup?.SubVertical || []) {
        const v = (verticalData?.LDPConfigQueryGroup?.Vertical || []).find(
          (vert) => vert?.VerticalId === sv?.VerticalId
        );
        if (v && sv) {
          options.push({
            vertical: v,
            subvertical: sv,
            exists: !!data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity?.find(
              (cap) =>
                cap?.SubVerticalName === sv.SubVerticalName &&
                cap?.AffiliateId === processInput(watchAffiliateId)
            ),
          });
        }
      }

      options.sort((sv1, sv2) =>
        `${sv1.vertical.VerticalName} - ${sv1.subvertical.SubVerticalName}` >
        `${sv2.vertical.VerticalName} - ${sv2.subvertical.SubVerticalName}`
          ? 1
          : -1
      );
      setSubVerticalOptions(options);

      if (buyerCapSVCDataDetails) {
        setBuyerCapDetails(buyerCapSVCDataDetails);        
        setSubVerticalInput(
          options?.find(
            (sv) =>
              sv.subvertical.SubVerticalName ===
              buyerCapSVCDataDetails?.SubVerticalName
          ) || null
        );        
        setNotes(buyerCapSVCDataDetails?.Notes);
        // setFormValues(buyerCapSVCDataDetails);
      }
      setBuyerCapLoading(false);
    }
  }, [data, verticalData, subVerticalData, watchAffiliateId]);

  useEffect(() => {
    // if (watchAffiliateId) {
    setSubVerticalInput(null);
    // }
  }, [watchAffiliateId]);

  const [saveState, setSaveState] = useState<
    "preparing" | "validating" | "validated" | "ready"
  >("preparing");

  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  useEffect(() => {
    if (
      saveState === "validating" &&
      !isAffiliateIdActiveLoading &&
      isAffiliateIdActiveData
    ) {
      //this step ensures that API AffiliateId validation is complete before setting field errors
      setSaveState("validated");
    } else if (
      saveState === "validated" &&
      watchAffiliateId &&
      isAffiliateIdActiveData
    ) {
      if (isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive) {
        setSaveState("ready");
      } else {
        setSaveState("preparing");
        setError("AffiliateId", {
          type: "error",
          message: "AffiliateId is inactive, please set a different value",
        });
        setDisableButtons(false);
      }
    } else if (saveState === "ready" && watchAffiliateId) {
      handleSaveBuyerSubVerticalCapacity();
    }
  }, [
    isAffiliateIdActiveLoading,
    saveState,
    isAffiliateIdActiveData,
    watchAffiliateId,
  ]);

  const handleSaveBuyerSubVerticalCapacity = () => {        
    let data = getValues();
    
    let buyerSubVerticalContractCapacity = {
      ...data,
      SubVerticalName: subVerticalInput?.subvertical.SubVerticalName || buyerCapDetails?.SubVerticalName,
      MonthlyMaxCapacity: data?.MonthlyMaxCapacity
        ? parseInt(data?.MonthlyMaxCapacity.toString())
        : null,
      WeeklyMaxCapacity: data?.WeeklyMaxCapacity
        ? parseInt(data?.WeeklyMaxCapacity.toString())
        : null,
      DailyMaxCapacity: data?.DailyMaxCapacity
        ? parseInt(data?.DailyMaxCapacity.toString())
        : null,
      AffiliateId: data?.AffiliateId ? parseInt(`${data?.AffiliateId}`) : null,
    };

    saveBuyerSubVerticalCapacity({
      variables: { buyerSubVerticalContractCapacity, UserId: null },
    }).then((response: any) => {
      if (buyerSubVerticalContractCapacity?.BuyerSubVerticalCapacityId > 0)
        toast.success("Buyer Sub Vertical Capacity updated successfully.");
      else {
        toast.success("Buyer Sub Vertical Capacity created successfully.");
      }
      refetch && refetch();
      close();
    });
  };

  const setFormValues = (
    data: BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity
  ) => {
    const arrayedDataValues = Object.values(data);
    Object.keys(data).map(function (key, index) {
      setValue(key, arrayedDataValues[index]);
    });
  };

  const handleTouch = () => {
    setTouched(!touched);
  };

  const onSubmit = (
    data: BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity
  ) => {
    setDisableButtons(true);
    if (data.AffiliateId) {
      //check affiliateId if valid
      setSaveState("validating");
      isAffiliateIdActive({
        variables: {
          affiliateId: data.AffiliateId,
        },
      });
    } else {
      handleSaveBuyerSubVerticalCapacity();
    }
    return false;
  };

  return (
    <Paper>
      {queryLoad([buyerCapLoading], [error]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerSubVerticalCapacityId"
                defaultValue={buyerCapDetails?.BuyerSubVerticalCapacityId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerId"
                defaultValue={Number(buyerCapDetails?.BuyerId) ?? buyerId}
              />
              <TextField
                type="number"
                inputRef={register({
                  pattern: {
                    value: /^[1-9]\d*$/s,
                    message: "Invalid Affiliate Id",
                  },
                  maxLength: {
                    value: 5,
                    message: "Invalid Affiliate Id",
                  },
                  validate: (value: string) => {
                    if (value)
                      return existingAffiliateIds?.find(
                        (data) =>
                          data.AffiliateId === parseInt(value) &&
                          data.SubVerticalName ===
                            subVerticalInput?.subvertical.SubVerticalName
                      )
                        ? `AffiliateId: ${value} is already in use`
                        : true;
                    // else
                    //   return existingAffiliateIds?.includes(null)
                    //     ? `AffiliateId: null is already in use`
                    //     : true;
                  },
                })}
                name="AffiliateId"
                label="Affiliate Id"
                defaultValue={buyerCapDetails?.AffiliateId ?? ""}
                error={errors.AffiliateId && true}
                helperText={errors.AffiliateId && errors.AffiliateId?.message}
                variant="outlined"
                // disabled={data?.AffiliateId}
                InputProps={{ readOnly: !!buyerCapDetails?.AffiliateId }}
                // onBlur={() => {
                //   handleCheckAffililateId();
                // }}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                required
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={buyerCapDetails?.UserId ?? "Test User"}
              /> */}
              <Autocomplete
                id="sub-vertical-input"
                options={subVerticalOptions || []}
                getOptionLabel={(option) =>
                  `${option.vertical?.VerticalName} - ${
                    option.subvertical?.SubVerticalName
                  }${option.exists ? " (Used)" : ""}`
                }
                getOptionSelected={(option, value) =>
                  option.subvertical?.SubVerticalName ===
                  value?.subvertical?.SubVerticalName
                }
                getOptionDisabled={(option) => option.exists === true}
                value={subVerticalInput}
                onChange={(event: any, newValue: any) => {
                  //console.log("newValue", newValue);
                  setSubVerticalInput(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="SubVertical Name"
                    variant="outlined"
                    error={errors.SubVerticalName ? true : false}
                    helperText={
                      errors.SubVerticalName && errors.SubVerticalName?.message
                    }
                    inputRef={register(formError.SubVerticalName)}
                    name="SubVerticalName"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.DailyMaxCapacity)}
                error={errors.DailyMaxCapacity && true}
                helperText={
                  errors.DailyMaxCapacity && errors.DailyMaxCapacity?.message
                }
                name="DailyMaxCapacity"
                label="Daily Max Lead Capacity"
                type="number"
                defaultValue={buyerCapDetails?.DailyMaxCapacity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.WeeklyMaxCapacity)}
                error={errors.WeeklyMaxCapacity && true}
                helperText={
                  errors.WeeklyMaxCapacity && errors.WeeklyMaxCapacity?.message
                }
                name="WeeklyMaxCapacity"
                label="Weekly Max Lead Capacity"
                type="number"
                defaultValue={buyerCapDetails?.WeeklyMaxCapacity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.MonthlyMaxCapacity)}
                error={errors.MonthlyMaxCapacity && true}
                helperText={
                  errors.MonthlyMaxCapacity &&
                  errors.MonthlyMaxCapacity?.message
                }
                name="MonthlyMaxCapacity"
                label="Monthly Max Lead Capacity"
                type="number"
                defaultValue={buyerCapDetails?.MonthlyMaxCapacity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={buyerCapDetails?.IsActive ?? true}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.Notes)}
                error={errors.Notes && true}
                helperText={
                  (touched || errors.Notes) && (
                    <>
                      <span style={{ float: "left" }}>
                        {errors.Notes && errors.Notes?.message}
                      </span>
                      <span style={{ float: "right" }}>
                        {`${notes?.length} / 256`}
                      </span>
                    </>
                  )
                }
                name="Notes"
                label="Notes"
                defaultValue={buyerCapDetails?.Notes ?? ""}
                inputProps={{ maxLength: 256 }}
                onFocus={handleTouch}
                onBlur={handleTouch}
                onChange={(e) => setNotes(e.target.value)}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

export const BuyerSubVerticalCapacityFormModal = connect()(
  WrappedBuyerSubVerticalCapacityFormModal
);
