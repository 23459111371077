import gql from "graphql-tag";

export const GET_BUYERS = gql`
  query GetBuyers($where: String) {
    LDPConfigQueryGroup {
      Buyer(limit: 9999, where: $where) {
        BuyerId
        BuyerName
        BuyerContactLinkId
        CreatedDate
        UserId
        AccountManagerName
        BuyerType
      }
    }
  }
`;

export const SAVE_BUYER = gql`
  mutation SaveBuyer($buyerData: BuyerInputType!) {
    LDPConfigMutationGroup {
      SaveBuyer(BuyerDataInput: $buyerData)
    }
  }
`;

export const GET_BUYER_CONTACT = gql`
  query GetBuyerContact($where: String) {
    LDPConfigQueryGroup {
      BuyerContact(limit: 9999, where: $where) {
        BuyerContactId
        CellPhone
        ContactTypeId
        ModifiedDate
        CreatedDate
        Email
        FirstName
        LastName
        Title
        UserId
        WorkPhone
      }
    }
  }
`;

export const SAVE_BUYER_CONTACT = gql`
  mutation SaveBuyerContact($buyerContactInput: BuyerContactInput!) {
    LDPBuyerMutationGroup {
      SaveBuyerContact(BuyerContactData: $buyerContactInput) {
        BuyerContactId
      }
    }
  }
`;

