import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SAVE_BUYER_FILTERS } from "../../common/models/buyerFilters";
import { GET_LEAD_DATA_LOOKUP_CONFIG } from "../../common/models/config";
import {
  GET_CONTRACT_FILTER_OPERATION,
  GET_EXTERNAL_PROCEDURE,
} from "../../common/models/contractFilters";
import { GET_LEAD_FIELD_DATA } from "../../common/models/leadDataLookup";
import { GET_TABLE_DEFINITION } from "../../common/models/tableDefinition";
import { GetBuyerFilters_LDPConfigQueryGroup_GetBuyerFilters as BuyerFilter } from "../../common/models/types/GetBuyerFilters";
import { GetContractFilterOperation } from "../../common/models/types/GetContractFilterOperation";
import {
  GetExternalProcedures,
  GetExternalProceduresVariables,
  GetExternalProcedures_LDPConfigQueryGroup_GetExternalProcedures,
} from "../../common/models/types/GetExternalProcedures";
import { GetLeadFieldData } from "../../common/models/types/GetLeadFieldData";
import {
  GetSubVertical,
  GetSubVertical_LDPConfigQueryGroup_SubVertical,
} from "../../common/models/types/GetSubVertical";
import { GetTableDefinition } from "../../common/models/types/GetTableDefinition";
import { GetVertical } from "../../common/models/types/GetVertical";
import { LeadDataLookupConfig } from "../../common/models/types/LeadDataLookupConfig";
import { SaveBuyerFilter } from "../../common/models/types/SaveBuyerFilter";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import {
  DecimalPattern as testDecimal,
  SignedInteger as testNumeric,
} from "../../common/utils/regex";
import { queryLoad } from "../../components";
import { BuyerFilterInputType } from "../../types/graphql-global-types";

interface BuyerFilterFormModalProps {
  data: BuyerFilter;
  action: string;
  close: Function;
  refetch: Function;
  buyerId: string;
}
interface MappedSelectField {
  id: number;
  label: string;
}

interface FormInput extends BuyerFilterInputType {
  DefaultValue: string[];
}

export const BuyerFilterModal = ({
  action,
  data,
  close,
  refetch,
  buyerId,
}: BuyerFilterFormModalProps) => {
  const classes = useStyles();

  const [mappedVertical, setMappedVertical] = useState<MappedSelectField[]>();

  const [
    selectedVertical,
    setSelectedVertical,
  ] = useState<MappedSelectField | null>(null);
  const [mappedSubVertical, setMappedSubVertical] = useState<
    MappedSelectField[]
  >();

  const [
    selectedSubVertical,
    setSelectedSubVertical,
  ] = useState<MappedSelectField | null>(null);

  const [saveBuyerFilter] = useMutation<SaveBuyerFilter>(SAVE_BUYER_FILTERS);
  // const { register, handleSubmit, watch, errors, setError } = useForm<BuyerFilterInputType>();
  const {
    reset,
    register,
    handleSubmit,
    clearErrors,
    errors,
    setError,
    control,
    setValue,
    getValues,
    watch,
  } = useForm<FormInput>();

  const BuyerFilterConstraints = {
    BuyerFilterName: {
      required: {
        value: true,
        message: "Filter Name is required.",
      },
    },
    FieldName: {
      required: {
        value: true,
        message: "Field Name is required.",
      },
    },
    Operation: {
      required: {
        value: true,
        message: "Operation is required.",
      },
    },
    Value: {
      required: {
        value: true,
        message: "Value is required.",
      },
    },
    VerticalName: {
      required: {
        value: true,
        message: "Vertical is required.",
      },
    },
    SubVerticalName: {
      required: {
        value: true,
        message: "Sub Vertical is required.",
      },
    },
  };

  const watchValue = watch('Value');

  const [
    getAllContractFilterOperation,
    { data: contractFilterOperationData },
  ] = useLazyQuery<GetContractFilterOperation>(GET_CONTRACT_FILTER_OPERATION);

  /* const [getBuyer, { data: buyerData, error: buyerError }] = useLazyQuery<GetBuyers>(GET_BUYERS); */
  // const [] = useLazyQuery<GetBuyerFilters, GetBuyerFiltersVariables>(GET_BUYER_FILTERS, { fetchPolicy: 'network-only' });
  const [
    getLeadDataLookupConfig,
    { data: leadDataLookupConfigData },
  ] = useLazyQuery<LeadDataLookupConfig>(GET_LEAD_DATA_LOOKUP_CONFIG);
  const [
    getTableDefinition,
    { data: tableDefinitionData },
  ] = useLazyQuery<GetTableDefinition>(GET_TABLE_DEFINITION);

  const [getAllVertical, { data: verticalData }] = useLazyQuery<GetVertical>(
    GET_VERTICAL
  );

  const [
    getAllSubVertical,
    { data: subVerticalData },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const [
    getFilterProcs,
    { data: filterProcData, loading: filterProcLoading },
  ] = useLazyQuery<GetExternalProcedures, GetExternalProceduresVariables>(
    GET_EXTERNAL_PROCEDURE
  );

  const [
    mappedContractFilterOperation,
    setMappedContractFilterOperation,
  ] = useState<MappedSelectField[]>();
  const [
    selectedContractFilterOperation,
    setSelectedContractFilterOperation,
  ] = useState<MappedSelectField | null>(null);

  useEffect(() => {
    if (contractFilterOperationData) {
      setMappedContractFilterOperation(
        contractFilterOperationData?.LDPConfigQueryGroup?.FilterOperation?.map(
          (x) => {
            return {
              id: x?.FilterOperationId,
              label: `${x?.Operation}`,
            };
          }
        )
      );
    }
  }, [contractFilterOperationData]);

  useEffect(() => {
    const selected =
      mappedContractFilterOperation?.find(
        (contractFilterOperation: MappedSelectField) =>
          contractFilterOperation?.label.toLowerCase() ===
          formData?.Operation?.toLowerCase()
      ) || null;
    setSelectedContractFilterOperation(selected);
    setLoading(false);
  }, [mappedContractFilterOperation]);

  useEffect(() => {
    if (selectedVertical && selectedSubVertical) {
      getLeadDataLookupConfig({
        variables: {
          where: `VerticalId = ${selectedVertical?.id} AND SubVerticalId = ${selectedSubVertical?.id}`,
        },
      });

      getTableDefinition({
        variables: {
          schema: "lead",
          name: `${selectedVertical?.label}${selectedSubVertical?.label}`,
        },
      });
    }
  }, [selectedSubVertical]);

  const [fieldNameOptions, setFieldNameOptions] = useState<string[]>([]);

  useEffect(() => {
    let fields: string[] = [];
    leadDataLookupConfigData?.LDPConfigQueryGroup?.LeadDataLookupConfig?.map(
      (it: any) => fields.push(it?.ColumnName)
    );

    tableDefinitionData?.LDPIngestQueryGroup?.TableDefinition?.map((it: any) =>
      fields.push(it?.ColumnName)
    );

    fields = fields.filter((v, i, a) => a.indexOf(v) === i);
    fields.sort();

    setFieldNameOptions(fields);
  }, [leadDataLookupConfigData, tableDefinitionData]);

  const [
    getFieldDefaultValues,
    { data: fieldDefaultValues, loading: fieldDefaultValuesLoading },
  ] = useLazyQuery<GetLeadFieldData>(GET_LEAD_FIELD_DATA, {
    fetchPolicy: "network-only",
  });
  const [fieldDefaultValueOptions, setFieldDefaultValueOptions] = useState<
    string[]
  >([]);
  const [
    selectedFieldDefaultValueOption,
    setSelectedFieldDefaultValueOption,
  ] = useState(data?.Value || null);
  const [fieldName, setFieldName] = useState<any>(null);
  const [extProcedureName, setExtProcedureName] = useState<string | null>(null);
  const [
    extProcedure,
    setExtProcedure,
  ] = useState<GetExternalProcedures_LDPConfigQueryGroup_GetExternalProcedures | null>();

  const [mappedProcNames, setMappedProcNames] = useState<
    GetExternalProcedures_LDPConfigQueryGroup_GetExternalProcedures[]
  >([]);

  useEffect(() => {
    if (fieldName) {
      getFilterProcs({
        variables: {
          FieldName: fieldName,
          IsContractFilter: 0,
        },
      });
    }
  }, [fieldName, getFilterProcs]);

  useEffect(() => {
    if (filterProcData) {
      // populate options
      setMappedProcNames([
        // @ts-ignore
        ...filterProcData.LDPConfigQueryGroup?.GetExternalProcedures,
      ]);
    }
  }, [filterProcData]);

  useEffect(() => {
    if (extProcedureName && mappedProcNames) {
      const byProcDesc = mappedProcNames.find(
        (p) =>
          p?.Description?.toLowerCase() ===
          data.ProcedureDescription?.toLowerCase()
      );

      if (byProcDesc) {
        setExtProcedure(byProcDesc);
      } else {
        const byProcName = mappedProcNames.find(
          (p) =>
            p?.ProcedureName?.toLowerCase() === extProcedureName.toLowerCase()
        );
        setExtProcedure(byProcName);
      }
    } else {
      setExtProcedure(undefined);
    }
  }, [mappedProcNames, extProcedureName]);

  useEffect(() => {
    getAllContractFilterOperation();
    getFieldDefaultValues();
  }, []);

  const [formData, setFormData] = useState<FormInput>({
    ...data,
    DefaultValue: [],
  });

  useEffect(() => {
    /* console.log("data", data); */
    const dataCopy: FormInput = { ...data, DefaultValue: [] };

    if (data && data.IsList && data.Delimiter) {
      dataCopy.DefaultValue =
        ((data?.Value || "").length > 0
          ? data?.Value?.split(data.Delimiter)
          : []) || [];
    } else {
      dataCopy.DefaultValue = data.Value?.length ? [data.Value] : [];
    }

    dataCopy.Delimiter = dataCopy.Delimiter?.trim() || "";

    setFormData(dataCopy);
    reset(dataCopy);

    //Dont forget to setFieldName initial value! (when editing)
    setFieldName(data?.FieldName);
    setExtProcedureName(data?.ExternalProcedureName);
  }, [data]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fieldDefaultValues) {
      setFieldDefaultValueOptions(
        fieldDefaultValues.LDPIngestQueryGroup?.LeadDataLookup?.filter(
          (v) => !!v?.LeadDataValue
        ).map((v) => `${v?.LeadDataValue}`) || []
      );
    }
  }, [fieldDefaultValues]);

  useEffect(() => {
    if (verticalData) {
      const verticalMapped = verticalData?.LDPConfigQueryGroup?.Vertical?.map(
        (x) => {
          return {
            id: x?.VerticalId,
            label: `${x?.VerticalName}`,
          };
        }
      );
      setMappedVertical(verticalMapped);
      setSelectedVertical(
        verticalMapped?.find(
          (vertical: MappedSelectField) =>
            vertical?.label === formData?.VerticalName
        ) || null
      );
    } else if (!verticalData) {
      getAllVertical();
    }

    if (!subVerticalData) {
      getAllSubVertical();
    }
  }, [verticalData, subVerticalData]);

  useEffect(() => {
    if (selectedVertical) {
      const subVerticalList =
        subVerticalData?.LDPConfigQueryGroup?.SubVertical?.map(
          (item) => ({
            id: item?.SubVerticalId,
            label: item?.SubVerticalName,
            parent: item?.VerticalId,
          })
        ) ?? [];

      const options =
        (subVerticalList.filter(
          (it: any) => it?.parent === selectedVertical.id
        ) ?? []).map((it: any) => ({...it, label: `${it?.label}` }));
      setMappedSubVertical(options);
      setSelectedSubVertical(
        options?.find(
          (subVertical) =>
            subVertical?.label === data?.SubVerticalName ||
            subVertical?.label === formData?.SubVerticalName
        ) || null
      );
    }
  }, [selectedVertical]);

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const onSubmit = (buyerFilterInput: FormInput) => {

    const operation = buyerFilterInput?.Operation?.toLowerCase() ?? "";
    const value = buyerFilterInput?.Value?.toLowerCase() ?? "";
    const delimiter = `${formData.Delimiter}`;

    clearErrors('Delimiter');

    if (operation.search("contain") > -1) {
      if (delimiter && value.split(delimiter).length <= 1) {
        return setError("Delimiter", {
          type: "custom",
          message: "Please set the correct delimeter based from the declared value",
        });
      }
    } else if (delimiter && value.split(delimiter).filter((v) => v).length <= 1) {
      return setError("Delimiter", {
        type: "custom",
        message: "No delimiter needed for single value",
      });
    }
    setDisableButtons(true);


    let finalBuyerFilterInput = { ...buyerFilterInput };


    finalBuyerFilterInput.IsList = formData.Delimiter !== "";
    finalBuyerFilterInput.IsDecimal = false;
    finalBuyerFilterInput.IsNumeric = false;
    finalBuyerFilterInput.Delimiter = delimiter;

    const DataType: any = tableDefinitionData?.LDPIngestQueryGroup?.TableDefinition?.find(
      (tableDefinition) =>
        tableDefinition?.ColumnName === finalBuyerFilterInput.FieldName
    )?.DataType;

    if (delimiter !== "") {
      let values: string[] = buyerFilterInput.Value?.split(delimiter).filter((v) => v).map((v) => v.trim()) || [];

      finalBuyerFilterInput = {
        ...finalBuyerFilterInput,
        IsList: true,
        Value: values.join(delimiter),
      };

      if (testDecimal.test(values[0]) && DataType === "decimal") {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsDecimal: true };
      } else {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsDecimal: false };
      }

      //Check and set if Default value isNumeric
      if (
        testNumeric.test(values[0]) &&
        ["int", "bigint", "decimal"].includes(DataType)
      ) {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsNumeric: true };
      } else {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsNumeric: false };
      }
    } else {
      finalBuyerFilterInput = {
        ...finalBuyerFilterInput,
        IsList: false,
      };

      //Check and set if Default value isDecimal
      if (
        testDecimal.test(buyerFilterInput?.Value || "") &&
        DataType === "decimal"
      ) {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsDecimal: true };
      } else {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsDecimal: false };
      }

      //Check and set if Default value isNumeric
      if (
        testNumeric.test(buyerFilterInput?.Value || "") &&
        ["int", "bigint", "decimal"].includes(DataType)
      ) {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsNumeric: true };
      } else {
        finalBuyerFilterInput = { ...finalBuyerFilterInput, IsNumeric: false };
      }
    }

    delete finalBuyerFilterInput.DefaultValue;

    finalBuyerFilterInput.ExternalProcedureName =
      extProcedure?.ProcedureName ?? "";
    finalBuyerFilterInput.ProcedureParameterName =
      extProcedure?.ProcedureParameterName ?? "";
    finalBuyerFilterInput.ProcedureDescription =
      extProcedure?.Description ?? "";
    finalBuyerFilterInput.UserId = ''; // let API set the value

    saveBuyerFilter({
      variables: {
        buyerFilterInput: finalBuyerFilterInput,
      },
    })
      .then(() => {
        toast.success(
          `Buyer Filter ${buyerFilterInput?.BuyerFilterId > 0 ? "update" : "created"
          } successfully.`
        );
        setDisableButtons(false);
        refetch();
        close();
      })
      .catch(() => {
        setDisableButtons(false);
        toast.error("Unabled to save filter. Duplicate entry is not allowed!");
      });
  };

  const validateDelimiter = () => {
    if (!!formData.Delimiter && formData.DefaultValue.length < 2) {
      setError("Delimiter", {
        type: "custom",
        message: "No Delimiter needed for single value.",
      });
    } else {
      clearErrors("DefaultValue");
      clearErrors("Delimiter");
    }
  };

  const onDelimiterAndValueUpdate = (delimiter: string, values: string[]) => {
    const entries: string[] = [];

    for (const newValue of values) {
      let addValues = [newValue];
      if (!!delimiter) {
        addValues = newValue.split(delimiter);
      }

      for (const addValue of addValues) {
        if (!entries.includes(addValue)) {
          entries.push(addValue);
        }
      }
    }

    return { delimiter, entries };
  };

  const onDelimiterUpdate = (newDelimiter: string, useValues?: string[]) => {
    const result = onDelimiterAndValueUpdate(
      newDelimiter,
      useValues || watch('Value')?.split(newDelimiter) || formData.DefaultValue || []
    );

    if (!newDelimiter) {
      // changed to no delimiter
      // merge values as single value
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          Delimiter: newDelimiter,
          DefaultValue: [result.entries.join("|")],
        };
      });
    } else {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          Delimiter: newDelimiter,
          DefaultValue: result.entries,
        };
      });
    }
  };

  /* const onDefaultValueUpdate = (newValues: string[]) => {
    const delimiter = formData.Delimiter || "";
    const result = onDelimiterAndValueUpdate(delimiter, newValues);

    if (!delimiter && result.entries.length > 1) {
      // set a default delimiter but re-process using new delimiter
      onDelimiterUpdate("|", result.entries);
    } else {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          Delimiter: delimiter,
          DefaultValue: result.entries,
        };
      });
    }
  }; */

  useEffect(() => {
    formData && validateDelimiter();
  }, [formData]);

  useEffect(() => {
    clearErrors('Delimiter');
  }, [watchValue])

  return (
    <Paper className={classes.container}>
      {queryLoad([!!loading], []) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs>
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerFilterId"
                defaultValue={formData?.BuyerFilterId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerId"
                defaultValue={formData?.BuyerId ?? buyerId}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={formData?.UserId ?? "Test User"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(BuyerFilterConstraints.BuyerFilterName)}
                error={errors.BuyerFilterName && true}
                helperText={
                  errors.BuyerFilterName && errors.BuyerFilterName?.message
                }
                name="BuyerFilterName"
                label="Buyer Filter Name"
                defaultValue={formData?.BuyerFilterName ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="vertical-id-input"
                options={mappedVertical || []}
                getOptionLabel={(option) => option.label}
                value={selectedVertical}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedVertical(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vertical"
                    variant="outlined"
                    inputRef={register(BuyerFilterConstraints.VerticalName)}
                    error={errors.VerticalName ? true : false}
                    helperText={
                      errors.VerticalName && errors.VerticalName?.message
                    }
                    name="VerticalName"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="subvertical-id-input"
                options={mappedSubVertical || []}
                getOptionLabel={(option) => option.label}
                value={selectedSubVertical}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedSubVertical(newValue);
                }}
                disabled={!selectedVertical}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="SubVertical"
                    variant="outlined"
                    inputRef={register(BuyerFilterConstraints.SubVerticalName)}
                    error={errors.SubVerticalName ? true : false}
                    helperText={
                      errors.SubVerticalName && errors.SubVerticalName?.message
                    }
                    name="SubVerticalName"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="fieldname-id-input"
                options={fieldNameOptions}
                value={fieldName}
                getOptionLabel={(option) => option}
                // disabled={! leadLookupConfigData}
                loading={fieldDefaultValuesLoading}
                loadingText={"Loading..."}
                disabled={!selectedSubVertical}
                onChange={(event, newValue) => {
                  setFieldDefaultValueOptions([]);
                  setSelectedFieldDefaultValueOption(null);
                  if (newValue) {
                    getFieldDefaultValues({
                      variables: { where: `LeadDataColumnName="${newValue}"` },
                    });
                  }
                  setFieldName(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register(BuyerFilterConstraints.FieldName)}
                    error={errors.FieldName && true}
                    helperText={errors.FieldName && errors.FieldName?.message}
                    name="FieldName"
                    label="Field Name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="buyer-filter-operation-id-input"
                options={mappedContractFilterOperation || []}
                getOptionLabel={(option) => option.label}
                value={selectedContractFilterOperation}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedContractFilterOperation(newValue);
                  setFormData({ ...formData, Operation: newValue?.label });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operation"
                    variant="outlined"
                    error={errors.Operation ? true : false}
                    helperText={errors.Operation && errors.Operation?.message}
                    inputRef={register(BuyerFilterConstraints.Operation)}
                    name="Operation"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.Delimiter}
              >
                <InputLabel id="delimiter-value">Delimiter</InputLabel>
                <Select
                  labelId="delimiter-select"
                  inputRef={register}
                  name="Delimiter"
                  label="Delimiter"
                  value={formData.Delimiter || ""}
                  error={errors.Delimiter && true}
                  onChange={(event) => {
                    setValue("Delimiter", event.target.value);
                    onDelimiterUpdate(event.target.value as string);
                  }}
                >
                  <MenuItem value="">No Delimiter</MenuItem>
                  <MenuItem value="|">| (Pipe)</MenuItem>
                  <MenuItem value=",">, (Comma)</MenuItem>
                  <MenuItem value="^">^ (Caret)</MenuItem>
                </Select>
                {errors.Delimiter && (
                  <FormHelperText color="danger">
                    {errors.Delimiter?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Default Value"
                variant="outlined"
                inputRef={register(BuyerFilterConstraints.Value)}
                error={errors.Value && true}
                helperText={errors.Value && errors.Value?.message}
                name="Value"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                hidden
                inputRef={register}
                name="ExternalProcedureName"
                value={
                  extProcedureName ?? formData?.ExternalProcedureName ?? null
                }
              />
              <TextField
                hidden
                inputRef={register}
                name="ProcedureParameterName"
                defaultValue={formData?.ProcedureParameterName ?? null}
              />

              <Autocomplete
                id="resp-disp-input"
                options={
                  mappedProcNames.map((p) => ({
                    id: p?.ProcedureName,
                    label: p?.Description,
                  })) || []
                }
                getOptionLabel={(option) => `${option.label ?? ""}`}
                value={{
                  id: extProcedure?.ProcedureName ?? "",
                  label: extProcedure?.Description ?? "",
                }}
                onChange={(event, newValue) => {
                  setExtProcedureName(newValue?.id ?? null);
                }}
                disabled={filterProcLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Procedure Description"
                    variant="outlined"
                    error={errors.ProcedureDescription ? true : false}
                    helperText={
                      errors.ProcedureDescription &&
                      errors.ProcedureDescription?.message
                    }
                    name="ProcedureDescription"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={formData?.IsActive ?? false}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableButtons}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableButtons}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
