import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,  
  Divider,
  FormControlLabel,
  TextField,
  Switch,  
} from "@material-ui/core";
import { queryLoad } from "../../components";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SaveIcon from "@material-ui/icons/Save";

import { eventTracker as tracker } from '../../components/tracker';
import { GoogleUserMeta, GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { BuyerCapacityQuery, BuyerCapacityQueryVariables, BuyerCapacityQuery_LDPConfigQueryGroup_BuyerCapacity } from "../../common/models/types/BuyerCapacityQuery";
import { BuyerCapacityInputType } from "../../types/graphql-global-types";
import { GET_BUYER_CAPACITY, SAVE_BUYER_CAPACITY } from "../../common/models/buyerCapacity";
import { GetIsAffiliateIdActive } from "../../common/models/types/GetIsAffiliateIdActive";
import { GET_IS_AFFILIATE_ID_ACTIVE } from "../../common/models/contractMonetaryCapacity";

interface BuyerCapacityTotalCountFormModalProps {
  buyerId: any;
  data: BuyerCapacityQuery_LDPConfigQueryGroup_BuyerCapacity | null;
  action: string;
  close: Function;
  refetch?: Function;  
  existingAffiliateIds: (number | null)[];
}

// const formError = {};
export const BuyerCapacityFormModal = ({
  action,
  buyerId,
  data,
  close,
  refetch,  
  existingAffiliateIds,

}: BuyerCapacityTotalCountFormModalProps) => {     
  const googleUserMeta = GoogleUserMeta();
  const { register, handleSubmit, setValue, errors, watch, getValues, setError } = useForm<BuyerCapacityInputType>();  
  const classes = useStyles();  
  const [
    getIsAffiliateIdActive,
    { data: isAffiliateIdActiveData, loading: isAffiliateIdActiveLoading },
  ] = useLazyQuery<GetIsAffiliateIdActive>(
    GET_IS_AFFILIATE_ID_ACTIVE,
    { fetchPolicy: "network-only" }
  );

  const [saveState, setSaveState] = useState<'preparing' | 'validating' | 'validated' | 'ready'>('preparing');

  const watchAffiliateId = watch('AffiliateId');

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  
  const eventTracker = ({ data }: any) => {
    const blockers: any = { '@': '[at]', '.': '[dot]' };
    tracker({
      name: "CreateNewBuyerCapacity",
      caption: "Track Create New Buyer Capacity",
      values: {
        ...data,
        email: googleUserMeta?.email?.replace(/\@|\./g, it => blockers[it]) ?? null
      }
    });
  }

  const [saveBuyerCapacity] = useMutation(SAVE_BUYER_CAPACITY);

  const onSubmit = (dataInput: BuyerCapacityInputType) => {
    setDisableButtons(true);
    if(dataInput.AffiliateId){
      //check affiliateId if valid
      setSaveState('validating');
      getIsAffiliateIdActive({
        variables:{
          affiliateId: dataInput.AffiliateId
        }
      })
    } else {
      handleSaveBuyerCapacity();
    }
    return false;
  }

  const handleSaveBuyerCapacity = async () => {
    const dataInput = getValues();
    dataInput.UserId = data?.UserId ?? googleUserMeta?.email    
    dataInput.CreatedDate = data?.CreatedDate ?? new Date();  
    if (data?.BuyerCapacityId > 0)
    dataInput.ModifiedDate = new Date();  

    dataInput.MonthlyMaxCapacity = parseInt(`${dataInput?.MonthlyMaxCapacity}` || "");
    dataInput.WeeklyMaxCapacity = parseInt(`${dataInput?.WeeklyMaxCapacity}` || "");
    dataInput.DailyMaxCapacity = parseInt(`${dataInput?.DailyMaxCapacity}` || "");
    dataInput.AffiliateId = dataInput?.AffiliateId ? parseInt(`${dataInput?.AffiliateId}`) : null;
    dataInput.UserId = null;

    const result = await  saveBuyerCapacity({
      variables: { dataInput }
    }).then((response: any) => {
      if (dataInput.BuyerCapacityId < 1) {
        eventTracker({ dataInput });
        toast.success("Buyer Capacity created successfully.");
      }
      else toast.success("Buyer Capacity updated successfully.");

      refetch && refetch();
      close();
    });
    setDisableButtons(false);
  }

  useEffect(()=>{
    if(saveState === 'validating' && !isAffiliateIdActiveLoading && isAffiliateIdActiveData){ //this step ensures that API AffiliateId validation is complete before setting field errors
      setSaveState('validated');
    } else if(saveState === 'validated' && watchAffiliateId && isAffiliateIdActiveData){
      if(isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive){
        setSaveState('ready');
      } else {
        setSaveState('preparing');
        setError('AffiliateId', { type:'error', message: 'AffiliateId is inactive, please set a different value'});
        setDisableButtons(false);
      }
    } else if(saveState === 'ready' && watchAffiliateId){
      handleSaveBuyerCapacity();
    }
  },[isAffiliateIdActiveLoading, saveState, isAffiliateIdActiveData, watchAffiliateId]);


  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <TextField
            required
            hidden
            inputRef={register}
            name="BuyerCapacityId"
            defaultValue={data?.BuyerCapacityId ?? 0}
          />
          <TextField
            required
            hidden
            inputRef={register}
            name="BuyerId"
            defaultValue={data?.BuyerId ?? buyerId}
          />
          <Grid item xs={12}>
            <TextField
              inputRef={register({
                pattern: {
                  value: /^[1-9]\d*$/s, 
                  message: "Invalid Affiliate Id"
                },
                maxLength: {
                  value: 5,
                  message: "Invalid Affiliate Id"
                },
                validate: (value: string) => {
                 if(value) return existingAffiliateIds.includes(parseInt(value)) ? `AffiliateId: ${value} is already in use` : true;
                 else return existingAffiliateIds.includes(null) ? `AffiliateId: null is already in use` : true;
                }
              })}
              error={errors.AffiliateId && true}
              helperText={!isAffiliateIdActiveLoading && errors.AffiliateId && errors.AffiliateId?.message}
              name="AffiliateId"
              label="AffiliateId"
              defaultValue={data?.AffiliateId ?? ""}
              variant="outlined"
              type="number"
              disabled={(data?.AffiliateId || saveState === 'validating') ? true : false}
            />
          </Grid>
          <Grid item xs={12}>            
            <TextField
              inputRef={register}
              type="number"
              name="MonthlyMaxCapacity"
              label="Monthly Lead Max Capacity"
              defaultValue={data?.MonthlyMaxCapacity ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>            
            <TextField
              inputRef={register}
              type="number"                            
              name="WeeklyMaxCapacity"
              label="Weekly Lead Max Capacity"
              defaultValue={data?.WeeklyMaxCapacity ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>            
            <TextField
              inputRef={register}
              type="number"                            
              name="DailyMaxCapacity"
              label="Daily Lead Max Capacity"
              defaultValue={data?.DailyMaxCapacity ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? true}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Is Active"
            />
          </Grid>

          <Grid item xs={12}>
              <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
          
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
