import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add,
  AddCircle,
  Edit as EditIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { GET_BUYER_MONETARY_CAPACITY } from "../../common/models/buyerCapacity";
import {
  BuyerMonetaryCapacityConfig,
  BuyerMonetaryCapacityConfigVariables,
} from "../../common/models/types/BuyerMonetaryCapacityConfig";
import { dateToPreferredTimezone } from "../../common/utils/date";
import {
  ContentLoading,
  LDPUIDataTable,
  StatusIcon,
  useModal,
} from "../../components";
import {
  activeFilter,
  numberColumnFilterNullTo1000K,
} from "../../components/customMuiDatatableFilter";
import { RootState } from "../../state";
import { updateBuyerDetailForms } from "../../state/buyerSectionReducer";
import {
  dockForm,
  FormStateProps,
  openForm,
  resetForm,
} from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { BuyerMonetaryCapacityFormModal } from "./buyerMonetaryCapacityModal";

interface BuyerMonetaryCapacityListProps {
  buyerId: string;
  dispatch?: Function;
  formsSection?: FormStateProps;
}

const BuyerMonetaryCapacityList = ({
  buyerId,
  dispatch,
  formsSection,
}: BuyerMonetaryCapacityListProps) => {
  const [
    getBuyerMonetaryCapacities,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<
    BuyerMonetaryCapacityConfig,
    BuyerMonetaryCapacityConfigVariables
  >(GET_BUYER_MONETARY_CAPACITY, { fetchPolicy: "network-only" });

  const { Modal, closeModal, openModal, setContent } = useModal();

  useEffect(() => {
    getBuyerMonetaryCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`,
      },
    });
  }, []);

  const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
    if (rowData) {
      let newJson: any = {};
      muiColumns.map((column: any, key: number) => {
        newJson[column.name] = rowData[key];
      });
      return newJson;
    } else return null;
  };

  const existingAffiliateIds = useMemo(
    () =>
      data?.LDPConfigQueryGroup?.BuyerMonetaryCapacityConfig?.map(
        (d) => d?.AffiliateId
      ),
    [data]
  );

  const columnsMUI = [
    {
      name: "BuyerMonetaryCapacityConfigId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">Edit</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    openModal({
                      title: `Edit Buyer Monetary #${value}  Capacity`,
                      icon: <EditIcon />,
                      iconColor: "violet",
                      content: (
                        <BuyerMonetaryCapacityFormModal
                          action="edit"
                          buyerId={buyerId}
                          data={mapRowDataToColumns(
                            columnsMUI,
                            tableMeta.rowData
                          )}
                          close={closeModal}
                          refetch={() => {
                            getBuyerMonetaryCapacities({
                              variables: {
                                where: `BuyerId = ${buyerId}`,
                              },
                            });
                          }}
                          existingAffiliateIds={[]}
                        />
                      ),
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    const HISTORY_TAB_ID = `buyer-monetary-capacity-${value}-history`;

                    const formProps = {
                      formId: HISTORY_TAB_ID,
                      formTitle: `Buyer Monetary Capacity ID#${value} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: (
                        <HistoryModal
                          ConfigName="BuyerMonetaryCapacityConfig"
                          ConfigId={value}
                          TabContainerId={`history-buyer-monetary-capacity-${value}-list-tabs`}
                        />
                      ),
                      formData: {
                        ConfigName: "BuyerMonetaryCapacityConfig",
                        ConfigId: value,
                        ...tableMeta.tableData[tableMeta.rowIndex],
                      },
                      formProps: {
                        closeTab: () =>
                          dispatch &&
                          dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                      },
                    };

                    dispatch &&
                      dispatch(
                        updateBuyerDetailForms({
                          buyerId: `buyer-${buyerId}`,
                          formIds: [HISTORY_TAB_ID],
                        })
                      );

                    dispatch &&
                      dispatch(
                        formsSection?.formContainers[HISTORY_TAB_ID] &&
                          formsSection?.formContainers[HISTORY_TAB_ID]
                            .isLastOpenedDocked
                          ? dockForm(formProps)
                          : openForm(formProps)
                      );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
      },
    },
    {
      name: "MaxMonthlyBudget",
      label: "Monthly Cap($)",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
        customBodyRender: (value: any) => (value !== null ? `$${value}` : ""),
      },
    },
    {
      name: "MaxWeeklyBudget",
      label: "Weekly Cap($)",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
        customBodyRender: (value: any) => (value !== null ? `$${value}` : ""),
      },
    },
    {
      name: "MaxDailyBudget",
      label: "Daily Cap($)",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
        customBodyRender: (value: any) => (value !== null ? `$${value}` : ""),
      },
    },
    {
      name: "AffiliateId",
      label: "AffiliateId",
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: true,
        ...activeFilter,
      },
    },
    {
      name: "Notes",
      label: "Notes",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "UserId",
      label: "User ID",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  // console.log("Add Buyer Monetary Capacity")
                  openModal({
                    title: "Add Buyer Monetary Capacity",
                    icon: <Add />,
                    iconColor: "green",
                    content: (
                      <BuyerMonetaryCapacityFormModal
                        action="edit"
                        buyerId={buyerId}
                        data={mapRowDataToColumns(columnsMUI, {})}
                        close={closeModal}
                        refetch={() => {
                          getBuyerMonetaryCapacities({
                            variables: {
                              where: `BuyerId = ${buyerId}`,
                            },
                          });
                        }}
                        existingAffiliateIds={existingAffiliateIds || []}
                      />
                    ),
                  });
                }}
              >
                Add Buyer Monetary Capacity
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!data && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId={`buyer-monetary-${buyerId}-caps-list`}
          restoreFilters={true}
          title={"Buyer Monetary Capacity"}
          data={data?.LDPConfigQueryGroup?.BuyerMonetaryCapacityConfig ?? []}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    formsSection: state.formsSection,
  }),
  null
)(BuyerMonetaryCapacityList);
