import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GET_ACCOUNT_MANAGER_LIST } from "../../common/models/accountManagers";
import { GET_BUYER_CONTACT, SAVE_BUYER } from "../../common/models/buyers";
import { GetAccountManagerList } from "../../common/models/types/GetAccountManagerList";
import { GetBuyerContact } from "../../common/models/types/GetBuyerContact";
import { GetBuyers_LDPConfigQueryGroup_Buyer } from "../../common/models/types/GetBuyers";
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { queryLoad } from "../../components";
import { eventTracker as tracker } from "../../components/tracker";
import { signalBuyerSaved } from "../../state/buyerSectionReducer";
import { BuyerInputType } from "../../types/graphql-global-types";

interface BuyerFormModalProps {
  data: GetBuyers_LDPConfigQueryGroup_Buyer | null;
  action: string;
  close: Function;
  refetch: Function;
  dispatch: Function;
  googleUserMeta?: GoogleUserMetaProp;
}

interface MappedSelectField {
  id: number;
  label: string;
}

const formError = {
  BuyerName: {
    required: {
      value: true,
      message: "Buyer Name is required.",
    },
  },
  AccountManagerName: {
    required: {
      value: true,
      message: "Account Manager Name is required.",
    },
    validate: (value) => {
      return !!value.trim();
    },
  },
  BuyerContactLinkId: {
    required: {
      value: true,
      message: "BuyerContactLinkId is required.",
    },
  },
};

const WrappedBuyerFormModal = ({
  action,
  data,
  close,
  refetch,
  dispatch,
  googleUserMeta,
}: BuyerFormModalProps) => {
  const { register, handleSubmit, watch, errors } = useForm<BuyerInputType>();
  const classes = useStyles();
  const pageTitle = action == "create" ? "Add New Buyer" : "Edit Buyer";
  const [saveBuyer] = useMutation(SAVE_BUYER);
  const [
    getAllBuyerContact,
    {
      data: buyerContactData,
      error: buyerContactError,
      loading: buyerContactLoading,
    },
  ] = useLazyQuery<GetBuyerContact>(GET_BUYER_CONTACT);

  const onSubmit = (data: BuyerInputType) => {
    let buyerData = {
      ...data,
      BuyerContactLinkId: mappedBuyerContactLinkId?.find(
        (buyer: MappedSelectField) => buyer?.label === data.BuyerContactLinkId
      )?.id,
      AccountManagerName: data?.AccountManagerName?.trim(),
      BuyerType: buyerType ? buyerType : null,
      UserId: "",
    };

    saveBuyer({ variables: { buyerData } }).then((response: any) => {
      if (buyerData?.BuyerId > 0) toast.success("Buyer updated successfully.");
      else {
        eventTracker({ buyerData });
        toast.success("Buyer created successfully.");
      }
      refetch();
      getAccountManagers();
      dispatch(signalBuyerSaved());
      close();
    });
  };

  const eventTracker = ({ buyerData }: any) => {
    const blockers: any = { "@": "[at]", ".": "[dot]" };
    tracker({
      name: "CreateNewBuyer",
      caption: "Track Create New Buyer",
      values: {
        ...buyerData,
        email:
          googleUserMeta?.email?.replace(/\@|\./g, (it) => blockers[it]) ??
          null,
      },
    });
  };

  const [mappedBuyerContactLinkId, setMappedBuyerContactLinkId] = useState<
    MappedSelectField[]
  >();
  const [
    selectedBuyerContactLinkId,
    setSelectedBuyerContactLinkId,
  ] = useState<MappedSelectField | null>(null);
  const [buyerType, setBuyerType] = useState<any>(data?.BuyerType || "");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (buyerContactData) {
      setMappedBuyerContactLinkId(
        buyerContactData?.LDPConfigQueryGroup?.BuyerContact?.map((x) => {
          return {
            id: x?.BuyerContactId,
            label: `${x?.FirstName?.trim()} ${x?.LastName?.trim()} - ${
              x?.BuyerContactId
            }`,
          };
        })
      );
    } else if (!buyerContactData) {
      getAllBuyerContact();
    }
  }, [buyerContactData]);

  useEffect(() => {
    setSelectedBuyerContactLinkId(
      mappedBuyerContactLinkId?.find(
        (buyerGroup: MappedSelectField) =>
          buyerGroup?.id === data?.BuyerContactLinkId
      ) || null
    );
    setLoading(false);
  }, [mappedBuyerContactLinkId]);

  const [accountManagerOptionList, setAccountManagerOptionList] = useState<
    string[]
  >();
  const [accountManager, setAccountManager] = useState<string | null>();

  const [
    getAccountManagers,
    {
      data: accountManagerData,
      error: accountManagerError,
      loading: accountManagerLoading,
    },
  ] = useLazyQuery<GetAccountManagerList>(GET_ACCOUNT_MANAGER_LIST, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (accountManagerData) {
      let _accountManagers: any = accountManagerData?.LDPConfigQueryGroup?.GetAccountManagers?.map(
        (d) => d?.AccountManagerName
      );

      setAccountManagerOptionList(_accountManagers);
    } else if (!accountManagerData) {
      getAccountManagers();
    }
  }, [accountManagerData]);

  useEffect(() => {
    setAccountManager(data?.AccountManagerName);
    setLoading(false);
  }, [accountManagerOptionList]);

  return (
    <Paper className={classes.contrainer}>
      {queryLoad([!!buyerContactLoading, !!loading], [buyerContactError]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerId"
                defaultValue={data?.BuyerId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={data?.UserId ?? "Test User"}
              />
              <TextField
                inputRef={register(formError.BuyerName)}
                error={errors.BuyerName && true}
                helperText={errors.BuyerName && errors.BuyerName?.message}
                name="BuyerName"
                label="Buyer Name"
                defaultValue={data?.BuyerName ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="buyer-type-select-label">Buyer Type</InputLabel>
                <Select
                  labelId="buyer-type-select-label"
                  name="BuyerType"
                  label="BuyerType"
                  value={buyerType}
                  onChange={(event) => setBuyerType(event.target.value)}
                >
                  <MenuItem value="">N/A</MenuItem>
                  <MenuItem value="Reseller Buyer">Reseller Buyer</MenuItem>
                  <MenuItem value="Direct Buyer">Direct Buyer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                id="account-manager-input"
                options={accountManagerOptionList || []}
                // getOptionLabel={option => option.label}
                value={accountManager}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setAccountManager(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Account Manager Name"
                    variant="outlined"
                    error={errors.AccountManagerName ? true : false}
                    helperText={
                      errors.AccountManagerName &&
                      errors.AccountManagerName?.message
                    }
                    inputRef={register(formError.AccountManagerName)}
                    name="AccountManagerName"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                id="buyer-contact-id"
                options={mappedBuyerContactLinkId || []}
                getOptionLabel={(option) => option.label}
                value={selectedBuyerContactLinkId}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedBuyerContactLinkId(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register(formError.BuyerContactLinkId)}
                    error={errors.BuyerContactLinkId && true}
                    helperText={
                      errors.BuyerContactLinkId &&
                      errors.BuyerContactLinkId?.message
                    }
                    name="BuyerContactLinkId"
                    label="Buyer Contact"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

export const BuyerFormModal = connect()(WrappedBuyerFormModal);
