import { Grid } from "@material-ui/core";
import React from "react";
import "react-table/react-table.css";
import BuyerCapacityTotalCountList from "./buyerCapacityTotalCountList";
import BuyerMonetaryCapacityList from "./buyerMonetaryCapacityList";
import BuyerSubVerticalCapacityTotalCountList from "./buyerSubVerticalCapacityTotalCountList";
import BuyerSubVerticalMonetaryCapacityList from "./buyerSubVerticalMonetaryCapacityList";

interface BuyerCapacityProps {
  buyerId: string;
}

const BuyerCapacity = ({
  buyerId
}: BuyerCapacityProps) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BuyerMonetaryCapacityList buyerId={buyerId} />
        </Grid>
        <Grid item xs={12}>
          <BuyerSubVerticalMonetaryCapacityList buyerId={buyerId} />
        </Grid>
        <Grid item xs={12}>
          <BuyerCapacityTotalCountList buyerId={buyerId} />
        </Grid>
        <Grid item xs={12}>
          <BuyerSubVerticalCapacityTotalCountList buyerId={buyerId} />
        </Grid>      
      </Grid>
    </>
  );
};

export default BuyerCapacity;