import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add,
  AddCircle,
  Edit as EditIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { GET_BUYER_SUBVERTICAL_CAPACITY } from "../../common/models/buyerCapacity";
import {
  BuyerSubVerticalCapacity,
  BuyerSubVerticalCapacityVariables,
  BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity,
} from "../../common/models/types/BuyerSubVerticalCapacity";
import {
  GetSubVertical,
  GetSubVertical_LDPConfigQueryGroup_SubVertical,
} from "../../common/models/types/GetSubVertical";
import {
  GetVertical,
  GetVertical_LDPConfigQueryGroup_Vertical,
} from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { dateToPreferredTimezone } from "../../common/utils/date";
import {
  ContentLoading,
  LDPUIDataTable,
  StatusIcon,
  useModal,
} from "../../components";
import {
  activeFilter,
  numberColumnFilterNullTo1000K,
  numberColumnFilterNullTo100K,
  stringColumnFilter,
} from "../../components/customMuiDatatableFilter";
import { RootState } from "../../state";
import { updateBuyerDetailForms } from "../../state/buyerSectionReducer";
import {
  dockForm,
  FormStateProps,
  openForm,
  resetForm,
} from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { BuyerSubVerticalCapacityFormModal } from "./buyerSubVerticalCapacityModal";

interface BuyerCapacityProps {
  buyerId: string;
  dispatch?: Function;
  buyerForms?: FormStateProps;
}

interface SubVerticalOption {
  vertical: GetVertical_LDPConfigQueryGroup_Vertical;
  subvertical: GetSubVertical_LDPConfigQueryGroup_SubVertical;
  exists: boolean;
}

const BuyerSubVerticalCapacityTotalCountList = ({
  buyerId,
  dispatch,
  buyerForms,
}: BuyerCapacityProps) => {
  const [
    getBuyerSubVerticalCapacities,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<BuyerSubVerticalCapacity, BuyerSubVerticalCapacityVariables>(
    GET_BUYER_SUBVERTICAL_CAPACITY,
    { fetchPolicy: "network-only" }
  );

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData /* 
      error: subVerticalError,
      loading: subVerticalLoading, */,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const { Modal, closeModal, openModal } = useModal();

  const [subVerticalFilter, setSubVerticalFilters] = useState<string[]>([]);
  const [activeStateFilter, setActiveStateFilter] = useState<string[]>([]);
  /* const [ ready, setIsReady ] = useState(false); */
  /* const [ svDropdownOpen, setSVDropdownOpen ] = useState(false); */
  const [subVerticalOptions, setSubVerticalOptions] = useState<
    SubVerticalOption[]
  >([]);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  useEffect(() => {
    // console.log('Get Buyer SubVertical Capacities');
    getBuyerSubVerticalCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`,
      },
    });
  }, []);

  useEffect(() => {
    getAllVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [data, error]);

  useEffect(() => {
    getAllSubVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [verticalData, verticalError]);

  /* useEffect(() => {
    setIsReady(true);
  }, [subVerticalData, subVerticalError]); */

  useEffect(() => {
    if (data && verticalData && subVerticalData) {
      const options: SubVerticalOption[] = [];
      for (const sv of subVerticalData.LDPConfigQueryGroup?.SubVertical || []) {
        const v = (verticalData?.LDPConfigQueryGroup?.Vertical || []).find(
          (vert) => vert?.VerticalId === sv?.VerticalId
        );
        if (v && sv) {
          options.push({
            vertical: v,
            subvertical: sv,
            exists: !!data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity?.find(
              (cap) => cap?.SubVerticalName === sv.SubVerticalName
            ),
          });
        }
      }

      options.sort((sv1, sv2) =>
        `${sv1.vertical.VerticalName} - ${sv1.subvertical.SubVerticalName}` >
        `${sv2.vertical.VerticalName} - ${sv2.subvertical.SubVerticalName}`
          ? 1
          : -1
      );
      setSubVerticalOptions(options.filter((sv) => sv.exists)); // display only active sub verticals SAA-2139
      // setSubVerticalFilters( options.map((opt) => `${opt.subvertical.SubVerticalName}`) );
      // setSelectedIndexes( options.map((opt, i) => i + 1) );
    }
  }, [data, verticalData, subVerticalData]);

  const existingAffiliateIds = useMemo(
    () =>
      data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity?.map(
        (
          d: BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity
        ) => ({
          AffiliateId: d?.AffiliateId,
          SubVerticalName: d?.SubVerticalName,
        })
      ),
    [data]
  );

  /* const anchorRef = React.useRef(null); */

  /* const handleMenuItemClick = (event:React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    if(index === 0){
      if(selectedIndexes.length === subVerticalOptions.length){
        // clear
        setSelectedIndexes([]);
      } else {
        setSelectedIndexes(subVerticalOptions
          .map((svo, i) => i ).map(n => n + 1));
      }
      
    } else {
      if(selectedIndexes.includes(index)){
        setSelectedIndexes(selectedIndexes.filter(i => i !== index));
      } else {
        setSelectedIndexes([...selectedIndexes, index]);
      }
    }
  }; */

  const handleSubVerticalFilterDeletes = (
    filterList: string[][],
    filterPos: number,
    index: number
  ) => {
    if (filterPos === -1) {
      filterList[index] = [];
    } else {
      filterList[index].splice(filterPos, 1);
    }

    const selected = subVerticalOptions
      .map((svo, i) =>
        filterList[index].includes(`${svo.subvertical.SubVerticalName}`)
          ? i
          : -1
      )
      .filter((n) => n > -1)
      .map((n) => n + 1);

    setSelectedIndexes(selected);

    return filterList;
  };

  const columnsMUI = [
    {
      name: "BuyerSubVerticalCapacityId",
      id: "BuyerSubVerticalCapacityIdToolIcons",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">
                      Edit Buyer Capacity
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    openModal({
                      title: "Edit Buyer SubVertical Capacity",
                      icon: <EditIcon />,
                      iconColor: "green",
                      content: (
                        <BuyerSubVerticalCapacityFormModal
                          action={"edit"}
                          buyerCapId={value}
                          buyerId={buyerId}
                          close={() => {
                            closeModal();
                            getBuyerSubVerticalCapacities({
                              variables: {
                                where: `BuyerId = ${buyerId}`,
                              },
                            });
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    var HISTORY_FORM_ID = `buyer-${buyerId}-buyer-sv-capacity-history-${value}`;

                    const formProps = {
                      formId: HISTORY_FORM_ID,
                      formTitle: `Buyer Sub-Vertical Capacity ID#${value} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: (
                        <HistoryModal
                          ConfigName="BuyerSubVerticalCapacity"
                          ConfigId={value}
                          TabContainerId={`history-buyer-sv-capacity-${value}-list-tabs`}
                        />
                      ),
                      formData: {
                        ConfigName: "BuyerCapacity",
                        ConfigId: value,
                        ...tableMeta.tableData[tableMeta.rowIndex],
                      },
                      formProps: {
                        closeTab: () =>
                          dispatch &&
                          dispatch(resetForm({ formId: HISTORY_FORM_ID })),
                      },
                    };

                    dispatch &&
                      dispatch(
                        updateBuyerDetailForms({
                          buyerId: `buyer-${buyerId}`,
                          formIds: [HISTORY_FORM_ID],
                        })
                      );
                    dispatch &&
                      dispatch(
                        buyerForms?.formContainers[HISTORY_FORM_ID] &&
                          buyerForms.formContainers[HISTORY_FORM_ID]
                            .isLastOpenedDocked
                          ? dockForm(formProps)
                          : openForm(formProps)
                      );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "SubVerticalName",
      label: "SubVertical",
      options: {
        viewColumns: true,
        filter: true,
        filterList: subVerticalFilter,
        ...stringColumnFilter,
        customFilterListOptions: {
          update: handleSubVerticalFilterDeletes,
        },
      },
    },

    {
      name: "MonthlyMaxCapacity",
      label: "Monthly Cap",
      options: {
        viewColumns: true,
        filter: true,
        ...numberColumnFilterNullTo1000K,
      },
    },
    {
      name: "WeeklyMaxCapacity",
      label: "Weekly Cap",
      options: {
        viewColumns: true,
        filter: true,
        ...numberColumnFilterNullTo100K,
      },
    },
    {
      name: "DailyMaxCapacity",
      label: "Daily Cap",
      options: {
        viewColumns: true,
        filter: true,
        ...numberColumnFilterNullTo100K,
      },
    },
    {
      name: "AffiliateId",
      label: "Affiliate ID",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: true,
        filter: true,
        filterList: activeStateFilter,
        ...activeFilter,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
      },
    },
    {
      name: "Notes",
      label: "Notes",
      options: {
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        viewColumns: true,
        filter: false,
      },
    },
  ];

  /** change columns on custom filter changes */
  const [tableCols, setTableCols] = useState<any[]>(columnsMUI);
  useEffect(() => {
    setSubVerticalFilters(
      subVerticalOptions
        .filter((svo, i) => selectedIndexes.includes(i + 1))
        .map((svo) => `${svo.subvertical.SubVerticalName}`)
    );

    if (selectedIndexes.length === 0) {
      setActiveStateFilter([]);
    }
  }, [selectedIndexes]);

  useEffect(() => {
    const newCols = [...tableCols];
    const svIndex = newCols.findIndex((col) => col.name === "SubVerticalName");

    if (svIndex > -1) {
      newCols[svIndex].options = {
        ...newCols[svIndex].options,
        ...stringColumnFilter,
        filterList: subVerticalFilter,
        customFilterListOptions: {
          update: handleSubVerticalFilterDeletes,
        },
      };
      setTableCols(newCols);
    }
  }, [subVerticalFilter]);

  useEffect(() => {
    const newCols = [...tableCols];
    const activeIndex = newCols.findIndex((col) => col.name === "IsActive");

    if (activeIndex > -1) {
      newCols[activeIndex].options = {
        ...newCols[activeIndex].options,
        filterList: activeStateFilter,
      };
      setTableCols(newCols);
    }
  }, [activeStateFilter]);

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "scroll",
    fixedHeader: true,
  };

  /* useEffect(() => {
    
  }, [selectedIndexes]); */

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={3}>
            <Grid
              container
              spacing={1}
              justify="flex-end"
              justifyContent="flex-end"
            >
              {/* <Grid item xs={12} sm={6} md={3}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                  <Button size="large" fullWidth style={{
                    fontSize: 'small',
                    whiteSpace: 'nowrap', 
                  }}>
                    {subVerticalOptions.length > 0 ? (
                      selectedIndexes.length === 0 ? "Select Sub Vertical" : (
                        selectedIndexes.includes(0) || selectedIndexes.length >= subVerticalOptions.length ? "All SubVerticals" : (
                          selectedIndexes.length === 1 ? 
                            `${subVerticalOptions[selectedIndexes[0] - 1]?.vertical.VerticalName} - ${subVerticalOptions[selectedIndexes[0] - 1]?.subvertical.SubVerticalName}` :
                            `${subVerticalOptions.filter((svo, idx) => selectedIndexes.includes(idx + 1)).map((svo) => svo.subvertical.SubVerticalName).join(', ').substring(0, 17)}...`
                        )
                      )
                    ) : ''}</Button>
                  <Button
                    color="primary"
                    size="large"
                    aria-controls={svDropdownOpen ? 'subvertical-filter-button-menu' : undefined}
                    aria-expanded={svDropdownOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => {
                      setSVDropdownOpen(!svDropdownOpen);
                    } }
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper style={{ zIndex: 999 }} open={svDropdownOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => {
                          setSVDropdownOpen(false);
                        } }>
                          <MenuList id="subvertical-filter-button-menu">
                            <MenuItem
                              key={`all-options`}
                              selected={ selectedIndexes.includes(0) || selectedIndexes.length >= subVerticalOptions.length }
                              onClick={(event) => {
                                
                                handleMenuItemClick(event, 0);
                                setActiveStateFilter(["Yes"]);
                              }}
                            >
                              All Sub-Verticals
                            </MenuItem>
                            {subVerticalOptions.map((option, index) => (
                              <MenuItem
                                key={`${option.vertical.VerticalId} - ${option.subvertical.SubVerticalId}`}
                                selected={selectedIndexes.includes(index + 1)}
                                onClick={(event) => {
                                  // setSubVerticalFilters( [`${option.subvertical.SubVerticalName}`] );
                                  handleMenuItemClick(event, index + 1);
                                  setActiveStateFilter(["Yes"]);
                                }}
                              >
                                {`${option.vertical.VerticalName} - ${option.subvertical.SubVerticalName}`}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<AddCircle />}
                  onClick={() => {
                    openModal({
                      title: "Add Buyer Subvertical Lead Capacity",
                      icon: <Add />,
                      iconColor: "green",
                      content: (
                        <BuyerSubVerticalCapacityFormModal
                          action={"create"}
                          buyerId={buyerId}
                          close={() => {
                            closeModal();
                            getBuyerSubVerticalCapacities({
                              variables: {
                                where: `BuyerId = ${buyerId}`,
                              },
                            });
                          }}
                          existingAffiliateIds={existingAffiliateIds || []}
                        />
                      ),
                    });
                  }}
                >
                  Add Buyer Subvertical Lead Capacity
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {!data && <ContentLoading showTip={true} />}
          <LDPUIDataTable
            ldpTableId={`buyer-sv-lead-cap-${buyerId}`}
            restoreFilters={true}
            title={"Buyer Subvertical Lead Capacity"}
            data={data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity ?? []}
            columns={columnsMUI}
            options={options}
          />
        </Grid>
        <Modal />
      </Grid>
    </>
  );
};

// export default BuyerSubVerticalCapacityTotalCountList;

export default connect(
  (state: RootState) => ({
    buyerForms: state.formsSection,
  }),
  null
)(BuyerSubVerticalCapacityTotalCountList);
