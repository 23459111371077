import { Grid } from "@material-ui/core";
import React from "react";
import "react-table/react-table.css";
import BuyerFilterList from "./buyerFilterList";

interface BuyerFilterProps {
  buyerId: string;
}

const BuyerFilter = ({
  buyerId
}: BuyerFilterProps) => {
  return (
    <>
      <Grid container spacing={3}>
        <BuyerFilterList buyerId={buyerId} />
      </Grid>
    </>
  );
};

export default BuyerFilter;