import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { ContentLoading, LDPUIDataTable } from "..";
import { RootState } from "../../state";
import { deleteTab, updateTabContainers } from "../../state/tabsReducer";
import BuyerAffiliatePriceHistory from "./buyerAffiliatePriceHistory";

interface BuyerAffiliatePriceDrillinProps {
  dispatch: Function;
  generalDataStore: RootState['allPurposeDataState'];
  buyerId: string;
  tabContainer: string;
  tabSections: RootState["tabsSection"];
}

const BuyerAffiliatePriceDrillin = ({
  dispatch,
  generalDataStore,
  buyerId,
  tabContainer: TAB_CONTAINER,
  tabSections,
}: BuyerAffiliatePriceDrillinProps) => {

  const historyDataId = `Buyer#${buyerId}_AffiliatePriceData`;
  const handleCloseTab = (tabId: string) => {
    dispatch &&
      dispatch(
        deleteTab({
          tabContainer: TAB_CONTAINER,
          tabId: tabId,
        })
      );
  };

  const handleAddTab = (title: string, content: JSX.Element, tabId: string) => {
    const newTabId = `history-${TAB_CONTAINER}-${tabId}`;
    var currentTabs = tabSections.tabContainers[TAB_CONTAINER].tabs;
    const tabFocus = currentTabs.findIndex(
      (obj: { tabId: string }) => obj.tabId === newTabId
    );

    if (tabFocus > 0) {
      dispatch &&
        dispatch(
          updateTabContainers({
            [TAB_CONTAINER]: {
              tabFocus: tabFocus,
              tabs: currentTabs,
            },
          })
        );
    } else {
      dispatch &&
        dispatch(
          updateTabContainers({
            [TAB_CONTAINER]: {
              tabFocus: currentTabs.length,
              tabs: [
                ...currentTabs,
                {
                  tabId: newTabId,
                  title,
                  content,
                  closeTab: () => {
                    handleCloseTab(newTabId);
                  },
                },
              ],
            },
          })
        );
    }
  };

  const columnsMUI = [
    {
      name: "AffiliateId",
      label: "Affiliate Id",
      options: {
        viewColumns: true,
      },
    },
    {
      name: "BuyerAffiliatePriceId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">
                      View Affiliate Price History
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    handleAddTab(
                      `Affiliate:${tableMeta.rowData[0]}`,
                      //@ts-ignore
                      <BuyerAffiliatePriceHistory
                        buyerId={buyerId}
                        tabContainer={TAB_CONTAINER}
                        buyerAffiliatePriceId={value}
                        affiliateId={tableMeta.rowData[0]}
                      />,
                      `${TAB_CONTAINER}-${value}`
                    );
                  }}
                >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        {!generalDataStore[historyDataId] ? (
          <ContentLoading showTip={true} />
        ) : (
          <LDPUIDataTable
            ldpTableId={`buyer-${buyerId}-affiliate-price-list-breakdown`}
            restoreFilters={true}
            title={"Buyer Affiliate Price"}
            data={generalDataStore[historyDataId] ?? []}
            columns={columnsMUI}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    tabSections: state.tabsSection,
    generalDataStore: state.allPurposeDataState,
  }),
  null
)(BuyerAffiliatePriceDrillin);
