import gql from "graphql-tag";

export const GET_BUYER_AFFILIATE_PRICE = gql`
  query GetBuyerAffiliatePrice(
      $where: String
      $limit: Int
      $orderby: String
    ) {
    LDPConfigQueryGroup {
      GetBuyerAffiliatePrice(
        where: $where
        limit: $limit
        orderby: $orderby
      ) {
        AffiliateId
        BuyerAffiliatePriceId
        BuyerId
        CreatedDate
        IsActive
        ModifiedDate
        Notes
        Price
        UserId
      }
    }
  }
`;

export const GET_BUYER_AFFILIATE_PRICE_HISTORY = gql`
  query GetBuyerAffiliatePriceHistory($buyerAffiliatePriceId: Long) {
    LDPConfigQueryGroup {
      GetBuyerAffiliatePriceHistory(BuyerAffiliatePriceId: $buyerAffiliatePriceId){
        AffiliateId
        BuyerAffiliatePriceId
        BuyerId
        CreatedDate
        HistoryAction
        HistoryDate
        TransactionId
        IsActive
        ModifiedDate
        Notes
        Price
        UserId
      }
    }
  }
`;
export const SAVE_BUYER_AFFILIATE_PRICE = gql`
  mutation SaveBuyerAffiliatePrice(
    $affiliatePriceData: MergeBuyerAffiliatePriceInputType!
  ) {
    LDPConfigMutationGroup {
      SaveBuyerAffiliatePrice(AffiliatePriceData: $affiliatePriceData) {
        ActionTaken
        BuyerAffiliatePriceId
      }
    }
  }
`;

export const DEL_BUYER_AFFILIATE_PRICE = gql`
  mutation DelBuyerAffiliatePrice(
    $buyerId: Long!
    $affiliateIDs: [Long!]
  ) {
    LDPConfigMutationGroup {
      DelBuyerAffiliatePrice(
        BuyerID: $buyerId
        AffiliateIDs: $affiliateIDs
      ) {
        ActionTaken
        BuyerAffiliatePriceId
      }
    }
  }
`;
