import { navigate } from "gatsby";
import React, { cloneElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ContentContainer, useModal } from "../../components";
import { tabExist, TabProps, TabsContainer } from "../../components/tabs";
import { RootState } from "../../state";
import { updateTabContainers } from "../../state/tabsReducer";


import { AddCircle } from "@material-ui/icons";
import { updateBuyerDetailForms } from "../../state/buyerSectionReducer";
import { closedForm, FormDataProps, triggeredForm } from "../../state/formReducer";
import BuyerCapacity from "./buyerCapacity";
import BuyerFilter from "./buyerFilter";
import BuyerView from "./buyerView";
import BuyerAffiliatePrice from "./buyerAffiliatePrice";

interface Props {
  dispatch: Function;
  tabSections: RootState['tabsSection'];
  buyerId: string;
  formSection: RootState['formsSection'];
  buyerDetail: any;
}

// const TAB_CONTAINER = "buyer-page-tabs";

const BuyerPage = ({ tabSections, dispatch, buyerId, formSection, buyerDetail }: Props) => {

  const TAB_CONTAINER = `buyer-${buyerId}`;
  
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabBuyers, setTabBuyers] = useState<TabProps[]>([]);

  const [formIds, setFormIds] = useState<string[]>();
  const [formsInitialized, setFormsInitialized] = useState(false);
  const [tabsInitialized, setTabsInitialized] = useState(false);
  const { Modal, closeModal, openModal } = useModal();

  const handleDocking = (formData: FormDataProps) => {
    if (formData) {
      const _tabExist = tabExist(tabBuyers, formData.formId);
      let _tabs: TabProps[] = (_tabExist > 0) ? tabBuyers?.filter((tab, index) => (index !== _tabExist)) : tabBuyers;

      if (_tabExist > 0 && formData.formId.includes('request-body-history')) {
        setTabFocus(_tabExist);
      } else {
        if (formData.isDocked) {
          closeModal();
          dispatch(updateTabContainers(
            {
              [TAB_CONTAINER]: {
                tabFocus: _tabs?.length,
                tabs: [
                  ..._tabs,
                  {
                    title: formData.formTitle,
                    tabId: formData.formId,
                    content: (typeof formData.formComponent !== 'undefined' &&
                      cloneElement(
                        formData.formComponent,
                        {
                          ...formData,
                          data: formData.formData,
                          close: formData.formProps.closeTab,
                          isDocked: formData.isDocked,
                        }
                      )
                    ),
                    closeTab: (tabs: TabProps[]) => {
                      handleCloseTab(tabs);
                    },
                  },
                ],
              }
            }
          ));
        } else if (formData.isModal) {
          dispatch(updateTabContainers(
            {
              [TAB_CONTAINER]: {
                tabFocus: formData?.formProps?.tabFocus ? formData?.formProps?.tabFocus : (tabFocus < _tabs.length) ? tabFocus : (_tabs.length - 1),
                tabs: _tabs,
              }
            }
          ));
          openModal({
            title: formData.formTitle,
            icon: formData.formIcon ?? <AddCircle />,
            iconColor: "orange",
            content: (typeof formData.formComponent !== 'undefined' &&
              cloneElement(
                formData.formComponent,
                {
                  ...formData,
                  data: formData.formData,
                  close: closeModal,
                  isDocked: formData.isDocked,
                }
              )
            ),
            customHeaderButtons: <div />,
          });
        }
      }
    }

    //defrag the form trigger
    dispatch(triggeredForm({ formId: formData.formId }));
  };

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: (tabs.length - 1),
          tabs: tabs,
        }
      })
    );
  };

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const qa = queryParams.get("qa");

    if (! tabSections.tabContainers[TAB_CONTAINER]) {
      let initialTabContainerFocus = 0;

      let initialTabs: TabProps[] = [
        {
          title: "Details",
          tabId: `buyer-${buyerId}-detail`,
          content: <BuyerView buyerId={buyerId} />,
        },
        {
          title: "Capacity",
          tabId: `buyer-${buyerId}-capacity`,
          content: <BuyerCapacity buyerId={buyerId} />,
        },
        {
          title: "Filters",
          tabId: `buyer-${buyerId}-filter`,
          content: <BuyerFilter buyerId={buyerId} />,
        },
        {
          title: "Affiliate Price",
          tabId: `buyer-${buyerId}-affiliate-price`,
          content: <BuyerAffiliatePrice buyerId={buyerId} />,
        },
      ];

      /* if(process.env.BRANCH_NAME === "production" || process.env.GATSBY_BRANCH === "production"){
        const isQATesting = localStorage.getItem('qaTesting') || false;

        if (! isQATesting)
          initialTabs.pop(); // disable filters on production
      } */

      const initialLeadsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialLeadsTabContainer));
    } else {
      if (tabSections.tabContainers[TAB_CONTAINER]) {
        setTabBuyers(tabSections.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(tabSections.tabContainers[TAB_CONTAINER].tabFocus);
        setTabsInitialized(true);
      }
    }
  }, [tabSections]);

  useEffect(() => {
    if (!buyerDetail[TAB_CONTAINER]) {
      dispatch(
        updateBuyerDetailForms({
          buyerId: `buyer-${buyerId}`,
          formIds: [
             //default form Ids for buyer page
          ]
        })
      );
    } else {
      setFormIds(buyerDetail[TAB_CONTAINER].formIds);
      setFormsInitialized(true);
    }
  }, [buyerDetail]);

  useEffect(() => {
    if (tabsInitialized && formsInitialized && formIds && formSection) {
      formIds.forEach(formId => {
        if (formSection.formContainers[formId] && formSection.formContainers[formId].isTriggered) {
          handleDocking(formSection.formContainers[formId]);
        } else if (formSection.formContainers[formId] && formSection.formContainers[formId].isClosed) {
          const _tabExist = tabExist(tabBuyers, formId);
          let _tabs: TabProps[] = (_tabExist > 0) ? tabBuyers?.filter((tab, index) => (index !== _tabExist)) : tabBuyers;

          handleCloseTab(_tabs);
          dispatch(closedForm({ formId: formId }));
        }
      });
    }
  }, [formSection, tabsInitialized, formsInitialized, formIds]);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={tabBuyers}
        onCloseTab={setTabBuyers}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
      <Modal />
    </ContentContainer>
  );
};

const BuyerPageWrapper = connect((state: RootState) => ({
  tabSections: state.tabsSection,
  formSection: state.formsSection,
  buyerDetail: state.buyerSection.buyerDetail,
}), null)(BuyerPage);

export default BuyerPageWrapper;